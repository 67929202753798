@charset "utf-8";

/* 
------------------------------------------------------------
@Third party plugins customization for smart-forms 
------------------------------------------------------------ */

/*
========================================================= 
JQUERY NUMERIC STEPPER PLUGIN | 
https://github.com/xFlatlinex/Numeric-Stepper
========================================================= 
*/

.stepper-wrap {
  position: relative;
  display: block;
  font: 11px Arial, sans-serif;
  input {
    text-align: right;
    padding-right: 20px;
  }
}

.stepper-btn-wrap {
  position: absolute;
  top: 0;
  right: -34px;
  width: 34px;
  height: 100%;
  overflow: hidden;
  background: #F5F5F5;
  border: 1px solid #ccc;
  border-width: 1px 1px 1px 0;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  a {
    display: block;
    height: 50%;
    overflow: hidden;
    line-height: 100%;
    text-align: center;
    text-decoration: none;
    text-shadow: 1px 1px 0 #fff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-indent: -9999px;
    background: #F5F5F5;
    cursor: default;
    color: #666;
    &:hover {
      background: #F9F9F9;
    }
  }
}

.stepper-btn-up:before, .stepper-btn-dwn:before {
  content: '';
  border: 4px dashed;
  pointer-events: none;
  border-color: #9F9F9F transparent;
  border-color: #9F9F9F rgba(255, 255, 255, 0);
  position: absolute;
  right: 14px;
  z-index: 1;
  height: 0;
  top: 32%;
  width: 0;
}

.stepper-btn-up:before {
  border-bottom-style: solid;
  border-top: none;
}

.stepper-btn-dwn:before {
  margin-top: 10px;
  border-top-style: solid;
  border-bottom: none;
}

/*
========================================================= 
SPECTRUM COLOR PICKER PLUGIN | 
https://github.com/bgrins/spectrum
========================================================= 
*/

.sp-container {
  position: absolute;
  display: inline-block;
  *display: inline;
  z-index: 9999994;
  overflow: hidden;
  *zoom: 1;
  left: 0;
  top: 0;
  &.sp-flat {
    position: relative;
  }
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
}

.sp-top {
  position: relative;
  display: inline-block;
  width: 100%;
}

.sp-top-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.sp-color {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 20%;
}

.sp-hue {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 84%;
  height: 100%;
}

.sp-clear-enabled .sp-hue {
  top: 33px;
  height: 77.5%;
}

.sp-fill {
  padding-top: 80%;
}

.sp-sat, .sp-val {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.sp-alpha-enabled {
  .sp-top {
    margin-bottom: 18px;
  }
  .sp-alpha {
    display: block;
  }
}

.sp-alpha-handle {
  position: absolute;
  top: -4px;
  bottom: -4px;
  width: 6px;
  left: 50%;
  cursor: pointer;
  border: 1px solid black;
  background: white;
  opacity: .8;
}

.sp-alpha {
  display: none;
  position: absolute;
  bottom: -14px;
  right: 0;
  left: 0;
  height: 8px;
}

.sp-alpha-inner {
  border: solid 1px #333;
}

.sp-clear {
  display: none;
  &.sp-clear-display {
    background-position: center;
  }
}

.sp-clear-enabled .sp-clear {
  display: block;
  position: absolute;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 84%;
  height: 28px;
}

.sp-dd, .sp-clear, .sp-alpha, .sp-slider, .sp-dragger, .sp-preview, .sp-alpha-handle, .sp-container, .sp-replacer {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.sp-container {
  &.sp-dragging .sp-input, button {
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  &.sp-input-disabled .sp-input-container, &.sp-buttons-disabled .sp-button-container {
    display: none;
  }
}

.sp-palette-only .sp-picker-container, .sp-palette-disabled .sp-palette-container, .sp-initial-disabled .sp-initial {
  display: none;
}

.sp-sat {
  background-image: -webkit-gradient(linear, 0 0, 100% 0, from(#FFF), to(rgba(204, 154, 129, 0)));
  background-image: -webkit-linear-gradient(left, #FFF, rgba(204, 154, 129, 0));
  background-image: -moz-linear-gradient(left, #fff, rgba(204, 154, 129, 0));
  background-image: -o-linear-gradient(left, #fff, rgba(204, 154, 129, 0));
  background-image: -ms-linear-gradient(left, #fff, rgba(204, 154, 129, 0));
  background-image: linear-gradient(to right, #fff, rgba(204, 154, 129, 0));
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType = 1, startColorstr=#FFFFFFFF, endColorstr=#00CC9A81)";
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType = 1, startColorstr='#FFFFFFFF', endColorstr='#00CC9A81');
}

.sp-val {
  background-image: -webkit-gradient(linear, 0 100%, 0 0, from(#000000), to(rgba(204, 154, 129, 0)));
  background-image: -webkit-linear-gradient(bottom, #000000, rgba(204, 154, 129, 0));
  background-image: -moz-linear-gradient(bottom, #000, rgba(204, 154, 129, 0));
  background-image: -o-linear-gradient(bottom, #000, rgba(204, 154, 129, 0));
  background-image: -ms-linear-gradient(bottom, #000, rgba(204, 154, 129, 0));
  background-image: linear-gradient(to top, #000, rgba(204, 154, 129, 0));
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00CC9A81, endColorstr=#FF000000)";
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00CC9A81', endColorstr='#FF000000');
}

.sp-hue {
  background: -moz-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -ms-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -o-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(#ff0000), color-stop(0.17, #ffff00), color-stop(0.33, #00ff00), color-stop(0.5, #00ffff), color-stop(0.67, #0000ff), color-stop(0.83, #ff00ff), to(#ff0000));
  background: -webkit-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
}

.sp-1 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0000', endColorstr='#ffff00');
}

.sp-2 {
  height: 16%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffff00', endColorstr='#00ff00');
}

.sp-3 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ff00', endColorstr='#00ffff');
}

.sp-4 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffff', endColorstr='#0000ff');
}

.sp-5 {
  height: 16%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0000ff', endColorstr='#ff00ff');
}

.sp-6 {
  height: 17%;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff00ff', endColorstr='#ff0000');
}

.sp-hidden {
  display: none !important;
}

.sp-cf {
  &:before {
    content: "";
    display: table;
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
  *zoom: 1;
}

/* Hue slider bigger on mobile */
@media (max-device-width: 480px) {
  .sp-color {
    right: 40%;
  }
  .sp-hue {
    left: 63%;
  }
  .sp-fill {
    padding-top: 60%;
  }
}

.sp-dragger {
  border-radius: 5px;
  height: 5px;
  width: 5px;
  border: 1px solid #fff;
  background: #000;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
}

.sp-slider {
  position: absolute;
  top: 0;
  cursor: pointer;
  height: 3px;
  left: -1px;
  right: -1px;
  border: 1px solid #000;
  background: white;
  opacity: .8;
}

/* Theme Authors 
------------------------------ */

.sp-container {
  border-radius: 0;
  background-color: #ECECEC;
  border: solid 1px #CFCFCF;
  padding: 0;
  font: normal 12px "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  button, input {
    font: normal 12px "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.sp-color, .sp-hue, .sp-clear {
  font: normal 12px "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.sp-top {
  margin-bottom: 3px;
}

.sp-color, .sp-hue, .sp-clear {
  border: solid 1px #666;
}

.sp-input-container {
  float: right;
  width: 100px;
  margin-bottom: 4px;
}

.sp-initial-disabled .sp-input-container {
  width: 100%;
}

.sp-input {
  font-size: 12px !important;
  border: 1px inset;
  padding: 4px 5px;
  border: 1px solid #bbb;
  background: #ffffff;
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
  -o-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  width: 100%;
  color: #222;
  margin: 0;
  &:focus {
    border-color: #6BACED;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 8px rgba(82, 168, 236, 0.7);
    -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 8px rgba(82, 168, 236, 0.7);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 8px rgba(82, 168, 236, 0.7);
  }
  &.sp-validation-error {
    background: #FEEFE9;
    border-color: #E46B66;
    &:focus {
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 8px rgba(228, 70, 52, 0.7);
      -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 8px rgba(228, 70, 52, 0.7);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.15), 0 0 8px rgba(228, 70, 52, 0.7);
    }
  }
}

.sp-picker-container, .sp-palette-container {
  float: left;
  position: relative;
  padding: 10px;
  padding-bottom: 300px;
  margin-bottom: -290px;
}

.sp-picker-container {
  width: 172px;
  border-left: solid 1px #fff;
}

.sp-palette-container {
  border-right: solid 1px #ccc;
}

.sp-palette-only .sp-palette-container {
  border: 0;
}

.sp-palette .sp-thumb-el {
  display: block;
  position: relative;
  float: left;
  width: 24px;
  height: 15px;
  margin: 3px;
  cursor: pointer;
  border: solid 2px transparent;
  &:hover, &.sp-thumb-active {
    border-color: orange;
  }
}

.sp-thumb-el {
  position: relative;
}

.sp-initial {
  float: left;
  border: solid 1px #333;
  span {
    width: 30px;
    height: 25px;
    border: none;
    display: block;
    float: left;
    margin: 0;
  }
  .sp-clear-display {
    background-position: center;
  }
}

.sp-button-container, .sp-palette-button-container {
  float: right;
}

.sp-replacer {
  cursor: pointer;
  overflow: hidden;
  padding: 4px 10px;
  position: absolute;
  background: #F5F5F5;
  vertical-align: middle;
  border: solid 1px #CFCFCF;
  border-width: 1px 1px 1px 0;
  display: inline-block;
  *display: inline;
  height: 100%;
  color: #333;
  margin: 0;
  *zoom: 1;
  right: 0;
  &:hover, &.sp-active {
    border-color: #aaa;
    color: #111;
  }
  &.sp-disabled {
    cursor: default;
    border-color: silver;
    color: silver;
  }
}

.sp-dd {
  padding: 2px 0;
  height: 16px;
  line-height: 16px;
  float: left;
  font-size: 12px;
  color: #9F9F9F;
  position: relative;
  top: 6px;
}

.sp-preview {
  position: relative;
  width: 25px;
  height: 20px;
  border: solid 1px #222;
  margin-right: 5px;
  margin-top: 6px;
  float: left;
  z-index: 0;
}

.sp-palette {
  *width: 220px;
  max-width: 220px;
  .sp-thumb-el {
    width: 16px;
    height: 16px;
    margin: 2px 1px;
    border: solid 1px #d0d0d0;
  }
}

.sp-container {
  padding-bottom: 0;
  button {
    background-color: #eeeeee;
    background-image: -webkit-linear-gradient(top, #eeeeee, #cccccc);
    background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);
    background-image: -ms-linear-gradient(top, #eeeeee, #cccccc);
    background-image: -o-linear-gradient(top, #eeeeee, #cccccc);
    background-image: linear-gradient(to bottom, #eeeeee, #cccccc);
    border: 1px solid #aaa;
    border-bottom: 1px solid #aaa;
    border-radius: 3px;
    color: #333;
    font-size: 14px;
    line-height: 1;
    padding: 5px 4px;
    text-align: center;
    text-shadow: 0 1px 0 #eee;
    vertical-align: middle;
    &:hover {
      background-color: #dddddd;
      background-image: -webkit-linear-gradient(top, #dddddd, #bbbbbb);
      background-image: -moz-linear-gradient(top, #dddddd, #bbbbbb);
      background-image: -ms-linear-gradient(top, #dddddd, #bbbbbb);
      background-image: -o-linear-gradient(top, #dddddd, #bbbbbb);
      background-image: linear-gradient(to bottom, #dddddd, #bbbbbb);
      border: 1px solid #bbb;
      border-bottom: 1px solid #999;
      cursor: pointer;
      text-shadow: 0 1px 0 #ddd;
    }
    &:active {
      border: 1px solid #aaa;
      border-bottom: 1px solid #888;
      -webkit-box-shadow: inset 0 0 5px 2px #aaaaaa, 0 1px 0 0 #eeeeee;
      -moz-box-shadow: inset 0 0 5px 2px #aaaaaa, 0 1px 0 0 #eeeeee;
      -ms-box-shadow: inset 0 0 5px 2px #aaaaaa, 0 1px 0 0 #eeeeee;
      -o-box-shadow: inset 0 0 5px 2px #aaaaaa, 0 1px 0 0 #eeeeee;
      box-shadow: inset 0 0 5px 2px #aaaaaa, 0 1px 0 0 #eeeeee;
    }
  }
}

.sp-cancel {
  font-size: 11px;
  color: #d93f3f !important;
  margin: 0;
  padding: 2px;
  margin-right: 5px;
  vertical-align: middle;
  text-decoration: none;
  &:hover {
    color: #d93f3f !important;
    text-decoration: underline;
  }
}

.sp-palette span {
  &:hover, &.sp-thumb-active {
    border-color: #000;
  }
}

.sp-preview, .sp-alpha, .sp-thumb-el {
  position: relative;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);
}

.sp-preview-inner, .sp-alpha-inner, .sp-thumb-inner {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.sp-palette {
  .sp-thumb-inner {
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
  .sp-thumb-light.sp-thumb-active .sp-thumb-inner {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIVJREFUeNpiYBhsgJFMffxAXABlN5JruT4Q3wfi/0DsT64h8UD8HmpIPCWG/KemIfOJCUB+Aoacx6EGBZyHBqI+WsDCwuQ9mhxeg2A210Ntfo8klk9sOMijaURm7yc1UP2RNCMbKE9ODK1HM6iegYLkfx8pligC9lCD7KmRof0ZhjQACDAAceovrtpVBRkAAAAASUVORK5CYII=);
  }
  .sp-thumb-dark.sp-thumb-active .sp-thumb-inner {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAAMdJREFUOE+tkgsNwzAMRMugEAahEAahEAZhEAqlEAZhEAohEAYh81X2dIm8fKpEspLGvudPOsUYpxE2BIJCroJmEW9qJ+MKaBFhEMNabSy9oIcIPwrB+afvAUFoK4H0tMaQ3XtlrggDhOVVMuT4E5MMG0FBbCEYzjYT7OxLEvIHQLY2zWwQ3D+9luyOQTfKDiFD3iUIfPk8VqrKjgAiSfGFPecrg6HN6m/iBcwiDAo7WiBeawa+Kwh7tZoSCGLMqwlSAzVDhoK+6vH4G0P5wdkAAAAASUVORK5CYII=);
  }
}

.sp-clear-display {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(data:image/gif;base64,R0lGODlhFAAUAPcAAAAAAJmZmZ2dnZ6enqKioqOjo6SkpKWlpaampqenp6ioqKmpqaqqqqurq/Hx8fLy8vT09PX19ff39/j4+Pn5+fr6+vv7+wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAP8ALAAAAAAUABQAAAihAP9FoPCvoMGDBy08+EdhQAIJCCMybCDAAYUEARBAlFiQQoMABQhKUJBxY0SPICEYHBnggEmDKAuoPMjS5cGYMxHW3IiT478JJA8M/CjTZ0GgLRekNGpwAsYABHIypcAgQMsITDtWJYBR6NSqMico9cqR6tKfY7GeBCuVwlipDNmefAtTrkSzB1RaIAoXodsABiZAEFB06gIBWC1mLVgBa0AAOw==);
}

.sfcolor {
  padding-right: 63px;
  .gui-input + .sp-replacer {
    width: 63px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
  }
}

/*
========================================================= 
jQUERY UI SLIDER PIPS | TOOLTIPS + LABELS
http://simeydotme.github.io/jQuery-ui-Slider-Pips/
========================================================= 
*/

.ui-slider-horizontal.ui-slider-pips {
  margin-bottom: 0.5em;
}

.ui-slider-pips {
  .ui-slider-label, .ui-slider-pip-hide {
    display: none;
  }
  .ui-slider-pip-label .ui-slider-label {
    display: block;
  }
  .ui-slider-pip {
    width: 2em;
    height: 1em;
    line-height: 1em;
    position: absolute;
    font-size: 0.8em;
    color: #999;
    overflow: visible;
    text-align: center;
    top: 10px;
    left: 20px;
    margin-left: -1em;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .ui-slider-line {
    background: #999;
    width: 1px;
    height: 3px;
    position: absolute;
    left: 50%;
  }
  .ui-slider-label {
    position: absolute;
    top: 5px;
    left: 50%;
    margin-left: -1em;
    width: 2em;
  }
}

.ui-slider-pip:hover .ui-slider-label {
  color: black;
  font-weight: bold;
}

.ui-slider-vertical.ui-slider-pips {
  margin-bottom: 1em;
  margin-right: 10px;
  .ui-slider-pip {
    text-align: left;
    top: auto;
    left: 10px;
    margin-left: 0;
    margin-bottom: -0.5em;
  }
  .ui-slider-line {
    width: 3px;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 0;
  }
  .ui-slider-label {
    top: 50%;
    left: 0.5em;
    margin-left: 0;
    margin-top: -0.5em;
    width: 2em;
  }
}

/* UI slider tooltips 
------------------------------------------------------- */

.ui-slider-float {
  .ui-slider-handle:focus {
    outline: none;
    .ui-slider-tip, .ui-slider-tip-label {
      outline: none;
    }
  }
  .ui-slider-tip, .ui-slider-tip-label {
    position: absolute;
    visibility: hidden;
    margin-top: -10px;
    display: block;
    width: auto;
    padding: 5px 10px;
    margin-left: -50%;
    line-height: 20px;
    background: #000;
    border-radius: 3px;
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    color: #fff;
    opacity: 0;
    -webkit-transition-duration: 200ms, 200ms, 0;
    -moz-transition-duration: 200ms, 200ms, 0;
    -ms-transition-duration: 200ms, 200ms, 0;
    transition-duration: 200ms, 200ms, 0;
    -webkit-transition-property: opacity, top, visibility;
    -moz-transition-property: opacity, top, visibility;
    -ms-transition-property: opacity, top, visibility;
    transition-property: opacity, top, visibility;
    -webkit-transition-delay: 0, 0, 200ms;
    -moz-transition-delay: 0, 0, 200ms;
    -ms-transition-delay: 0, 0, 200ms;
    transition-delay: 0, 0, 200ms;
  }
  .ui-slider-handle {
    &:hover .ui-slider-tip, &:focus .ui-slider-tip {
      opacity: 1;
      top: -30px;
      visibility: visible;
      -webkit-transition-delay: 200ms, 200ms, 0;
      -moz-transition-delay: 200ms, 200ms, 0;
      -ms-transition-delay: 200ms, 200ms, 0;
      transition-delay: 200ms, 200ms, 0;
    }
  }
  .ui-slider-pip {
    &:hover .ui-slider-tip-label {
      opacity: 1;
      top: -30px;
      visibility: visible;
      -webkit-transition-delay: 200ms, 200ms, 0;
      -moz-transition-delay: 200ms, 200ms, 0;
      -ms-transition-delay: 200ms, 200ms, 0;
      transition-delay: 200ms, 200ms, 0;
    }
    .ui-slider-tip-label {
      top: 42px;
    }
    &:hover .ui-slider-tip-label {
      top: 32px;
      font-weight: normal;
    }
  }
  .ui-slider-tip:after {
    content: " ";
    width: 0;
    height: 0;
    border-top: 8px solid #000;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    position: absolute;
    bottom: -6px;
    left: 30%;
  }
  .ui-slider-pip .ui-slider-tip-label:after {
    content: " ";
    width: 0;
    height: 0;
    border-top: 8px solid #000;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    position: absolute;
    bottom: -6px;
    left: 30%;
    border-bottom: 8px solid #000;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-top: 0;
    top: -5px;
  }
}

/* Horizontal tooltip css triangle arrow 
------------------------------------------------ */

/* Vertical tooltip 
-------------------------------------------- */

.ui-slider-vertical.ui-slider-float {
  .ui-slider-tip, .ui-slider-tip-label {
    top: 50%;
    margin-top: -11px;
    width: auto;
    margin-left: 0px;
    left: -60px;
    color: #fff;
    -webkit-transition-duration: 200ms, 200ms, 0;
    -moz-transition-duration: 200ms, 200ms, 0;
    -ms-transition-duration: 200ms, 200ms, 0;
    transition-duration: 200ms, 200ms, 0;
    -webkit-transition-property: opacity, left, visibility;
    -moz-transition-property: opacity, left, visibility;
    -ms-transition-property: opacity, left, visibility;
    transition-property: opacity, left, visibility;
    -webkit-transition-delay: 0, 0, 200ms;
    -moz-transition-delay: 0, 0, 200ms;
    -ms-transition-delay: 0, 0, 200ms;
    transition-delay: 0, 0, 200ms;
  }
  .ui-slider-handle {
    &:hover .ui-slider-tip, &:focus .ui-slider-tip {
      top: 50%;
      margin-top: -11px;
    }
  }
  .ui-slider-pip {
    &:hover .ui-slider-tip-label {
      top: 50%;
      margin-top: -11px;
    }
    .ui-slider-tip-label {
      left: 47px;
    }
    &:hover .ui-slider-tip-label {
      left: 37px;
    }
  }
  .ui-slider-tip:after {
    border-left: 8px solid #000;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    position: absolute;
    bottom: 50%;
    margin-bottom: -5px;
    right: -14px;
    margin-left: 0;
    top: auto;
    left: auto;
  }
  .ui-slider-pip .ui-slider-tip-label:after {
    border-left: 8px solid #000;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    position: absolute;
    bottom: 50%;
    margin-bottom: -5px;
    right: -14px;
    margin-left: 0;
    top: auto;
    left: auto;
    border-right: 8px solid #000;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 0;
    right: auto;
    left: -6px;
  }
}

/* tooltips to right of slider 
--------------------------------------------------------------- */

/* tooltip css triangle arrow 
------------------------------------------------------------ */

/* ui slider pip themes 
---------------------------------------------- */

#alternating-slider {
  .ui-slider-pip {
    .ui-slider-line {
      height: 6px;
      top: 3px;
    }
    .ui-slider-label {
      top: 15px;
    }
    &:nth-child(even) {
      top: -33px;
      height: 15px;
      transform: scale(0.8) translateY(3px);
      opacity: 0.8;
      .ui-slider-label {
        top: 5px;
      }
      .ui-slider-line {
        top: 22px;
        height: 6px;
      }
      &.ui-slider-pip-selected-1 .ui-slider-label, &.ui-slider-pip-selected-2 .ui-slider-label, &.ui-slider-pip-selected-3 .ui-slider-label, &.ui-slider-pip-selected-4 .ui-slider-label {
        top: -3px;
      }
    }
  }
  .ui-slider-pip-initial-1 .ui-slider-label {
    color: #E80949;
  }
  .ui-slider-pip-initial-2 .ui-slider-label {
    color: #FF03CB;
  }
  .ui-slider-pip-initial-3 .ui-slider-label {
    color: #C309E8;
  }
  .ui-slider-pip-initial-4 .ui-slider-label {
    color: #920AFF;
  }
  .ui-slider-pip-selected-1 .ui-slider-label, .ui-slider-pip-selected-2 .ui-slider-label, .ui-slider-pip-selected-3 .ui-slider-label, .ui-slider-pip-selected-4 .ui-slider-label {
    color: white;
    width: 2.4em;
    padding: 4px 0;
    margin-left: -1.2em;
    border-radius: 2px;
    top: 10px;
  }
  .ui-slider-pip-selected-1 .ui-slider-label {
    background-color: #E80949;
  }
  .ui-slider-pip-selected-2 .ui-slider-label {
    background-color: #FF03CB;
  }
  .ui-slider-pip-selected-3 .ui-slider-label {
    background-color: #C309E8;
  }
  .ui-slider-pip-selected-4 .ui-slider-label {
    background-color: #920AFF;
  }
  .ui-slider-handle.ui-state-focus {
    z-index: 100;
  }
}

#double-label-slider.ui-slider .ui-slider-pip {
  top: -3px;
  .ui-slider-line {
    background: #E5E5E5;
    width: 16px;
    height: 16px;
    margin-left: -6px;
    box-shadow: 0 0 0 2px #E5E5E5;
    border-radius: 100%;
    transition: all 0.4s ease;
    top: -2px;
  }
  .ui-slider-label {
    width: 100px;
    margin-left: -50px;
    text-align: center;
    i, span {
      width: 100px;
      margin-left: -50px;
      text-align: center;
    }
    color: #888888;
    i {
      font-style: normal;
      font-size: 1.4em;
      position: absolute;
      top: -36px;
    }
    span {
      opacity: 0.6;
      font-size: 1.2em;
      line-height: 1.3;
      position: absolute;
      top: 18px;
      transition: opacity 0.3s ease;
    }
  }
  &:hover .ui-slider-label span, &:focus .ui-slider-label span {
    opacity: 1;
  }
  &.ui-slider-pip-selected .ui-slider-label {
    span {
      opacity: 1;
    }
    color: black;
  }
}

/*
========================================================= 
SMART FORMS MODAL PLUGIN
http://doptiq.com/smart-forms/
========================================================= 
*/

.smartforms-px {
  text-align: center;
  display: block;
}

.smartforms-modal-footer {
  padding-bottom: 15px;
}

.smartforms-modal-scroll {
  overflow: hidden;
}

.smartforms-modal-trigger {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  margin: 3em auto;
  text-align: center;
  color: #FFF;
  font-size: 16px;
  ffont-weight: 300;
  text-transform: uppercase;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  background: #4EBD4A;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  box-shadow: 0 3px 0 rgba(0, 0, 0, 0.07);
  text-decoration: none;
  padding: 0 30px;
}

.smartforms-modal-header {
  position: relative;
  padding: 15px 20px;
  background-color: #4EBD4A;
  color: #fff;
  h3 {
    margin: 0;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    font-size: 28px;
    font-weight: 300;
  }
}

.smartforms-modal-body {
  display: block;
}

.smartforms-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  height: 100%;
  width: 100%;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 0;
  filter: alpha(opacity = 0);
  visibility: hidden;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
  overflow-y: scroll;
  &.smartforms-modal-visible {
    opacity: 1;
    filter: alpha(opacity = 80);
    visibility: visible;
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
    transition: opacity 0.3s 0s, visibility 0s 0s;
  }
}

.smartforms-modal-container {
  position: relative;
  width: 90%;
  max-width: 600px;
  margin: 4em auto;
  background: #FFF;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-40px);
  -moz-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  -o-transform: translateY(-40px);
  transform: translateY(-40px);
  -webkit-backface-visibility: hidden;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
  .smartforms-modal-close {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 100%;
    line-height: 60px;
    background: #4EBD4A;
    opacity: 1;
    filter: alpha(opacity = 100);
    font-family: Arial, Baskerville, monospace;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    text-decoration: none;
    text-align: center;
    font-style: normal;
    font-size: 28px;
    color: #fff;
    z-index: 10;
    &:hover {
      opacity: 0.7;
      filter: alpha(opacity = 70);
    }
  }
}

.smartforms-modal-visible .smartforms-modal-container {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.smartforms-modal-container {
  .wrap-full {
    width: 100%;
  }
  .smart-wrap {
    padding: 0;
  }
  .smart-container {
    margin: 0 auto;
    position: relative;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -o-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
  }
}

@media only screen and (min-width: 1170px) {
  .smartforms-modal-container {
    margin: 5em auto;
  }
}

/*
========================================================= 
jQUERY STEPS PLUGIN
http://www.jquery-steps.com/
========================================================= 
*/

.wizard, .tabcontrol {
  display: block;
  overflow: hidden;
  width: 100%;
}

.wizard {
  &:after, &:before {
    display: table;
    content: "";
    line-height: 0;
  }
}

.tabcontrol {
  &:after, &:before {
    display: table;
    content: "";
    line-height: 0;
  }
}

.wizard {
  > {
    .steps {
      &:after, &:before {
        display: table;
        content: "";
        line-height: 0;
      }
    }
    .content {
      &:after, &:before {
        display: table;
        content: "";
        line-height: 0;
      }
    }
  }
  &:after {
    clear: both;
  }
}

.tabcontrol:after {
  clear: both;
}

.wizard {
  > {
    .steps:after, .content:after {
      clear: both;
    }
  }
  a {
    outline: 0;
  }
}

.tabcontrol a {
  outline: 0;
}

.wizard ul, .tabcontrol ul {
  list-style: none !important;
  padding: 0;
  margin: 0;
}

.wizard ul > li, .tabcontrol ul > li {
  display: block;
  padding: 0;
}

.wizard > .steps .current-info, .tabcontrol > .steps .current-info, .wizard > .content > .title, .tabcontrol > .content > .title {
  position: absolute;
  left: -999em;
}

.wizard {
  > .steps {
    position: relative;
    overflow: hidden;
    display: block;
    width: 100%;
  }
  &.vertical {
    > .steps {
      display: inline;
      float: left;
      width: 30%;
    }
    > .steps > ul > li {
      float: none;
      width: 100%;
    }
    > .content {
      float: left;
      margin: 0 2.5% 0.5em 2.5%;
      display: inline;
      width: 65%;
    }
    > .actions {
      display: inline;
      float: right;
      margin: 0 2.5%;
      width: 95%;
    }
    > .actions > ul > li {
      margin: 0 0 0 1em;
    }
  }
  > {
    .steps {
      .number {
        font-size: 1.429em;
      }
      > ul > li {
        width: 25%;
        float: left;
      }
    }
    .actions > ul > li {
      float: left;
    }
  }
  > {
    .steps {
      a {
        display: block;
        width: auto;
        margin: 0 0.5em 0.5em;
        padding: 1em 1em;
        text-decoration: none;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        &:hover, &:active {
          display: block;
          width: auto;
          margin: 0 0.5em 0.5em;
          padding: 1em 1em;
          text-decoration: none;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
        }
      }
      .disabled a {
        background: #ECEDEE;
        cursor: default;
        color: #aaa;
        &:hover, &:active {
          background: #ECEDEE;
          cursor: default;
          color: #aaa;
        }
      }
      .current a {
        background: #2184be;
        cursor: default;
        color: #fff;
        &:hover, &:active {
          background: #2184be;
          cursor: default;
          color: #fff;
        }
      }
      .done a {
        background: #9dc8e2;
        color: #fff;
        &:hover, &:active {
          background: #9dc8e2;
          color: #fff;
        }
      }
      .error a {
        background: #ff3111;
        color: #fff;
        &:hover, &:active {
          background: #ff3111;
          color: #fff;
        }
      }
    }
    .content {
      display: block;
      margin: 0;
      min-height: 7em;
      position: relative;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      overflow: hidden;
      width: auto;
    }
  }
  > {
    .content > .body {
      float: left;
      position: relative;
      width: 100%;
      height: 100%;
      padding: 10px 8px;
      padding-right: 10px;
      ul {
        list-style: disc !important;
        > li {
          display: list-item;
        }
      }
      > iframe {
        border: 0 none;
        width: 100%;
        height: 100%;
      }
    }
    .actions {
      position: relative;
      display: block;
      text-align: right;
      width: 100%;
    }
  }
  > .actions > ul {
    display: inline-block;
    text-align: right;
    > li {
      margin: 0 0.5em;
    }
  }
  > {
    .actions {
      a {
        background: #2184be;
        color: #fff;
        display: block;
        padding: 1em 1em;
        text-decoration: none;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        &:hover, &:active {
          background: #2184be;
          color: #fff;
          display: block;
          padding: 1em 1em;
          text-decoration: none;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
        }
      }
      .disabled a {
        background: #fff;
        cursor: default;
        color: #fff;
        &:hover, &:active {
          background: #fff;
          cursor: default;
          color: #fff;
        }
      }
      > ul > li.disabled {
        display: none;
      }
    }
    .loading .spinner {}
  }
}

/* Hide back button on step 1 */

.tabcontrol > {
  .steps {
    position: relative;
    display: block;
    width: 100%;
    > ul {
      position: relative;
      margin: 6px 0 0 0;
      z-index: 1;
      top: 1px;
      > li {
        float: left;
        padding: 1px;
        margin: 5px 2px 0 0;
        -webkit-border-top-left-radius: 5px;
        -webkit-border-top-right-radius: 5px;
        -moz-border-radius-topleft: 5px;
        -moz-border-radius-topright: 5px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        &:hover {
          background: #edecec;
          border: 1px solid #bbb;
          padding: 0;
        }
        &.current {
          background: #fff;
          border: 1px solid #bbb;
          border-bottom: 0 none;
          padding: 0 0 1px 0;
          margin-top: 0;
          > a {
            padding: 15px 30px 10px 30px;
          }
        }
        > a {
          color: #5f5f5f;
          padding: 10px 30px;
          display: inline-block;
          text-decoration: none;
          border: 0 none;
          margin: 0;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
  .content {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 35em;
    overflow: hidden;
    border-top: 1px solid #bbb;
    padding-top: 20px;
    > .body {
      float: left;
      position: absolute;
      width: 95%;
      height: 95%;
      padding: 2.5%;
      ul {
        list-style: disc !important;
        > li {
          display: list-item;
        }
      }
    }
  }
}

.wizard fieldset, .tabcontrol fieldset {
  border: 0;
  position: relative;
  display: block;
  z-index: 1;
}

/* Customized for smartforms v.4
 * Add .smart-steps class to .form-body
-------------------------------------------------------------- */

.smart-steps {
  .wizard {
    > .content > .body {
      padding: 0;
      padding-right: 4px;
    }
    fieldset {
      border: 0;
      position: relative;
      z-index: 1;
      display: block;
    }
  }
  .tabcontrol fieldset {
    border: 0;
    position: relative;
    z-index: 1;
    display: block;
  }
  .wizard {
    > .steps {
      position: relative;
      a {
        margin: 0;
        margin: 0 0 0.8em;
        text-align: center;
        &:hover, &:active {
          margin: 0;
          margin: 0 0 0.8em;
          text-align: center;
        }
        height: 54px;
        line-height: 54px;
        padding: 0 1em;
        padding-left: 2em;
        &:hover, &:active {
          height: 54px;
          line-height: 54px;
          padding: 0 1em;
          padding-left: 2em;
        }
        position: relative;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        &:before {
          content: '';
          width: 40px;
          height: 40px;
          position: absolute;
          right: -16px;
          -webkit-border-radius: 5px 2px 5px 0;
          -moz-border-radius: 5px 2px 5px 0;
          -o-border-radius: 5px 2px 5px 0;
          border-radius: 5px 2px 5px 0;
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
          background-color: inherit;
          border-color: inherit;
          border: none;
          top: 7px;
          z-index: 1;
        }
        &:after {
          content: "";
          border-top: 28px solid transparent;
          border-bottom: 28px solid transparent;
          border-left: 28px solid #fff;
          position: absolute;
          left: 0;
          top: -1px;
        }
      }
      li {
        &:first-child a:after, &:last-child a:before {
          display: none;
        }
      }
    }
    &.vertical > {
      .steps {
        overflow: visible;
        li:last-child a:before {
          display: block;
        }
        a {
          &:after, &:before {
            display: none;
          }
        }
        li:last-child a:before {
          display: none;
        }
        .current {
          a:before, &:last-child a:before {
            display: block;
          }
        }
        a {
          padding-left: 1em;
          &:hover, &:active {
            padding-left: 1em;
          }
        }
      }
      .content {
        padding-left: 20px;
        margin: 0;
        padding: 0 2px 20px 30px;
        width: 70%;
      }
    }
  }
  form {
    padding-bottom: 10px;
  }
  .wizard > .steps {
    position: relative;
    z-index: 99;
  }
  .xsteps {
    position: relative;
    z-index: 2;
  }
  .wizard > .steps .number {
    display: none;
  }
}

/* vertical steps  
-------------------------------------------------------------------- */

/* custom styling 
-------------------------------------------------------------------- */

.smart-container .step-group {
  padding-right: 5px;
}

.server-response {
  margin-bottom: 10px;
}

.smart-steps .wizard.vertical > .content .step-group {
  padding-right: 0;
}

.smart-container {
  position: relative;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}

.steps-progress .wizard > .steps {
  overflow: visible;
  padding-bottom: 20px;
  li:before {
    content: '';
    display: inline-block;
    position: absolute;
    height: 5px;
    width: 100%;
    bottom: 14px;
  }
  .disabled:before {
    background: #ECEDEE;
  }
  .current:before {
    background: #2184be;
  }
  .done:before {
    background: #9dc8e2;
  }
  .error:before {
    background: #ff3111;
  }
  .number {
    font-size: 11px;
    display: block;
    position: absolute;
    line-height: 22px;
    font-weight: bold;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 22px;
    height: 24px;
    width: 24px;
    top: 57px;
    left: 25%;
  }
  .disabled .number {
    background: #ECEDEE;
  }
  .current .number {
    background: #2184be;
  }
  .done .number {
    background: #9dc8e2;
  }
  .error .number {
    background: #ff3111;
  }
}

.smartform-box {
  background: #ECEDEE;
}

/* Number of steps 
------------------------------------------------------ */

.stp-six .wizard > .steps > ul > li {
  width: 16.66%;
}

.stp-five .wizard > .steps > ul > li {
  width: 20%;
}

.stp-four .wizard > .steps > ul > li {
  width: 25%;
}

.stp-three .wizard > .steps > ul > li {
  width: 33.33%;
}

.stp-two .wizard > .steps > ul > li {
  width: 50%;
}

/* @Steps theme colors 
------------------------------------------------------ */

/* @primary steps theme 
------------------------------------------------------ */

.steps-theme-primary .wizard > {
  .actions a {
    background: #4ebd4a;
    color: #fff;
    &:hover, &:active {
      background: #4ebd4a;
      color: #fff;
    }
  }
  .steps {
    .current {
      a {
        background: #4ebd4a;
        color: #fff;
        &:hover, &:active {
          background: #4ebd4a;
          color: #fff;
        }
      }
      &:before, .number {
        background: #4ebd4a;
        color: #fff;
      }
    }
    .done {
      a {
        background: #83D181;
        color: #fff;
        &:hover, &:active {
          background: #83D181;
          color: #fff;
        }
      }
      &:before, .number {
        background: #83D181;
        color: #fff;
      }
    }
  }
}

/* @primary steps black
------------------------------------------------------ */

.steps-theme-black .wizard > {
  .actions a {
    background: #505558;
    color: #fff;
    &:hover, &:active {
      background: #505558;
      color: #fff;
    }
  }
  .steps {
    .current {
      a {
        background: #505558;
        color: #fff;
        &:hover, &:active {
          background: #505558;
          color: #fff;
        }
      }
      &:before, .number {
        background: #505558;
        color: #fff;
      }
    }
    .done {
      a {
        background: #80898C;
        color: #fff;
        &:hover, &:active {
          background: #80898C;
          color: #fff;
        }
      }
      &:before, .number {
        background: #80898C;
        color: #fff;
      }
    }
  }
}

/* @primary steps blue
------------------------------------------------------ */

.steps-theme-blue .wizard > {
  .actions a {
    background: #00acee;
    color: #fff;
    &:hover, &:active {
      background: #00acee;
      color: #fff;
    }
  }
  .steps {
    .current {
      a {
        background: #00acee;
        color: #fff;
        &:hover, &:active {
          background: #00acee;
          color: #fff;
        }
      }
      &:before, .number {
        background: #00acee;
        color: #fff;
      }
    }
    .done {
      a {
        background: #53CFFF;
        color: #fff;
        &:hover, &:active {
          background: #53CFFF;
          color: #fff;
        }
      }
      &:before, .number {
        background: #53CFFF;
        color: #fff;
      }
    }
  }
}

/* @primary steps green
------------------------------------------------------ */

.steps-theme-green .wizard > {
  .actions a {
    background: #0E993C;
    color: #fff;
    &:hover, &:active {
      background: #0E993C;
      color: #fff;
    }
  }
  .steps {
    .current {
      a {
        background: #0E993C;
        color: #fff;
        &:hover, &:active {
          background: #0E993C;
          color: #fff;
        }
      }
      &:before, .number {
        background: #0E993C;
        color: #fff;
      }
    }
    .done {
      a {
        background: #0FC654;
        color: #fff;
        &:hover, &:active {
          background: #0FC654;
          color: #fff;
        }
      }
      &:before, .number {
        background: #0FC654;
        color: #fff;
      }
    }
  }
}

/* @primary steps purple
------------------------------------------------------ */

.steps-theme-purple .wizard > {
  .actions a {
    background: #9464e2;
    color: #fff;
    &:hover, &:active {
      background: #9464e2;
      color: #fff;
    }
  }
  .steps {
    .current {
      a {
        background: #9464e2;
        color: #fff;
        &:hover, &:active {
          background: #9464e2;
          color: #fff;
        }
      }
      &:before, .number {
        background: #9464e2;
        color: #fff;
      }
    }
    .done {
      a {
        background: #BB9BEC;
        color: #fff;
        &:hover, &:active {
          background: #BB9BEC;
          color: #fff;
        }
      }
      &:before, .number {
        background: #BB9BEC;
        color: #fff;
      }
    }
  }
}

/* @primary steps red
------------------------------------------------------ */

.steps-theme-red .wizard > {
  .actions a {
    background: #ee4f3d;
    color: #fff;
    &:hover, &:active {
      background: #ee4f3d;
      color: #fff;
    }
  }
  .steps {
    .current {
      a {
        background: #ee4f3d;
        color: #fff;
        &:hover, &:active {
          background: #ee4f3d;
          color: #fff;
        }
      }
      &:before, .number {
        background: #ee4f3d;
        color: #fff;
      }
    }
    .done {
      a {
        background: #F27D71;
        color: #fff;
        &:hover, &:active {
          background: #F27D71;
          color: #fff;
        }
      }
      &:before, .number {
        background: #F27D71;
        color: #fff;
      }
    }
  }
}

/* @primary steps yellow
------------------------------------------------------ */

.steps-theme-yellow .wizard > {
  .actions a {
    background: #faa226;
    color: #fff;
    &:hover, &:active {
      background: #faa226;
      color: #fff;
    }
  }
  .steps {
    .current {
      a {
        background: #faa226;
        color: #fff;
        &:hover, &:active {
          background: #faa226;
          color: #fff;
        }
      }
      &:before, .number {
        background: #faa226;
        color: #fff;
      }
    }
    .done {
      a {
        background: #F2B96A;
        color: #fff;
        &:hover, &:active {
          background: #F2B96A;
          color: #fff;
        }
      }
      &:before, .number {
        background: #F2B96A;
        color: #fff;
      }
    }
  }
}

/* @All themes error step 
---------------------------------------------------- */

.steps-theme-red .wizard > .steps .error {
  a {
    background: #ff3111;
    color: #fff;
    &:hover, &:active {
      background: #ff3111;
      color: #fff;
    }
  }
  &:before, .number {
    background: #ff3111;
    color: #fff;
  }
}

.steps-theme-blue .wizard > .steps .error {
  a {
    background: #ff3111;
    color: #fff;
    &:hover, &:active {
      background: #ff3111;
      color: #fff;
    }
  }
  &:before, .number {
    background: #ff3111;
    color: #fff;
  }
}

.steps-theme-green .wizard > .steps .error {
  a {
    background: #ff3111;
    color: #fff;
    &:hover, &:active {
      background: #ff3111;
      color: #fff;
    }
  }
  &:before, .number {
    background: #ff3111;
    color: #fff;
  }
}

.steps-theme-black .wizard > .steps .error {
  a {
    background: #ff3111;
    color: #fff;
    &:hover, &:active {
      background: #ff3111;
      color: #fff;
    }
  }
  &:before, .number {
    background: #ff3111;
    color: #fff;
  }
}

.steps-theme-purple .wizard > .steps .error {
  a {
    background: #ff3111;
    color: #fff;
    &:hover, &:active {
      background: #ff3111;
      color: #fff;
    }
  }
  &:before, .number {
    background: #ff3111;
    color: #fff;
  }
}

.steps-theme-yellow .wizard > .steps .error {
  a {
    background: #ff3111;
    color: #fff;
    &:hover, &:active {
      background: #ff3111;
      color: #fff;
    }
  }
  &:before, .number {
    background: #ff3111;
    color: #fff;
  }
}

.steps-theme-primary .wizard > .steps .error {
  a {
    background: #ff3111;
    color: #fff;
    &:hover, &:active {
      background: #ff3111;
      color: #fff;
    }
  }
  &:before, .number {
    background: #ff3111;
    color: #fff;
  }
}

/*
========================================================= 
SHOW HIDE PASSWORD PLUGIN
https://cloudfour.github.io/hideShowPassword/
========================================================= 
*/

/* Remove MS IE behavior
---------------------------------------------------- */

::-ms-reveal, ::-ms-clear {
  display: none !important;
}

/* Show-hide image  
---------------------------------------- */

.hideShowPassword-toggle {
  background-color: transparent;
  background-image: url('images/wink.png');
  background-image: url('images/wink.svg'), none;
  background-position: 0 center;
  background-repeat: no-repeat;
  border: 2px solid transparent;
  border-radius: 0.25em;
  cursor: pointer;
  font-size: 100%;
  height: 44px;
  margin: 0;
  max-height: 100%;
  padding: 0;
  overflow: 'hidden';
  text-indent: -999em;
  width: 46px;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.hideShowPassword-toggle-hide {
  background-position: -44px center;
}

.hideShowPassword-toggle {
  &:hover, &:focus {
    border-color: #0088cc;
    outline: transparent;
  }
}

/* Custom show-hide toggle 
-------------------------------------- */

.my-toggle {
  background: transparent;
  display: inline-block;
  border: 0;
  border-radius: 0.25em;
  color: #888;
  cursor: pointer;
  font-size: 11px;
  font-weight: bold;
  margin-right: 10px;
  padding: 10px;
  text-transform: uppercase;
  -moz-appearance: none;
  -webkit-appearance: none;
  right: 7px !important;
  height: 30px;
  line-height: 0;
  &:hover, &:focus {
    background-color: #eee;
    outline: transparent;
    color: #555;
  }
}

.hideShowPassword-toggle, .my-toggle {
  z-index: 3;
}

/*
========================================================= 
jQUERY SELECT 2 PLUGIN
https://select2.github.io/
========================================================= 
*/

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 28px;
    user-select: none;
    -webkit-user-select: none;
    .select2-selection__rendered {
      display: block;
      padding-left: 8px;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .select2-selection__clear {
      position: relative;
    }
  }
  &[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 8px;
    padding-left: 20px;
  }
  .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none;
    -webkit-user-select: none;
    .select2-selection__rendered {
      display: inline-block;
      overflow: hidden;
      padding-left: 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .select2-search--inline {
    float: left;
    .select2-search__field {
      box-sizing: border-box;
      border: none;
      font-size: 100%;
      margin-top: 5px;
      padding: 0;
      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }
    }
  }
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
  &[aria-selected] {
    cursor: pointer;
  }
}

.select2-container--open {
  .select2-dropdown {
    left: 0;
  }
  .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
  .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box;
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }
  &.select2-search--hide {
    display: none;
  }
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity = 0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.select2-container--default {
  .select2-selection--single {
    background-color: #fff;
    border: 1px solid #aaa;
    border-radius: 4px;
    .select2-selection__rendered {
      color: #444;
      line-height: 28px;
    }
    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
    }
    .select2-selection__placeholder {
      color: #999;
    }
    .select2-selection__arrow {
      height: 26px;
      position: absolute;
      top: 1px;
      right: 1px;
      width: 20px;
      b {
        border-color: #888 transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        height: 0;
        left: 50%;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        width: 0;
      }
    }
  }
  &[dir="rtl"] .select2-selection--single {
    .select2-selection__clear {
      float: left;
    }
    .select2-selection__arrow {
      left: 1px;
      right: auto;
    }
  }
  &.select2-container--disabled .select2-selection--single {
    background-color: #eee;
    cursor: default;
    .select2-selection__clear {
      display: none;
    }
  }
  &.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent #888 transparent;
    border-width: 0 4px 5px 4px;
  }
  .select2-selection--multiple {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;
    .select2-selection__rendered {
      box-sizing: border-box;
      list-style: none;
      margin: 0;
      padding: 0 5px;
      width: 100%;
      li {
        list-style: none;
      }
    }
    .select2-selection__placeholder {
      color: #999;
      margin-top: 5px;
      float: left;
    }
    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
      margin-top: 5px;
      margin-right: 10px;
    }
    .select2-selection__choice {
      background-color: #e4e4e4;
      border: 1px solid #aaa;
      border-radius: 4px;
      cursor: default;
      float: left;
      margin-right: 5px;
      margin-top: 5px;
      padding: 0 5px;
    }
    .select2-selection__choice__remove {
      color: #999;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      margin-right: 2px;
      &:hover {
        color: #333;
      }
    }
  }
  &[dir="rtl"] .select2-selection--multiple {
    .select2-selection__choice, .select2-selection__placeholder, .select2-search--inline {
      float: right;
    }
    .select2-selection__choice {
      margin-left: 5px;
      margin-right: auto;
    }
    .select2-selection__choice__remove {
      margin-left: 2px;
      margin-right: auto;
    }
  }
  &.select2-container--focus .select2-selection--multiple {
    border: solid black 1px;
    outline: 0;
  }
  &.select2-container--disabled {
    .select2-selection--multiple {
      background-color: #eee;
      cursor: default;
    }
    .select2-selection__choice__remove {
      display: none;
    }
  }
  &.select2-container--open {
    &.select2-container--above {
      .select2-selection--single, .select2-selection--multiple {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    &.select2-container--below {
      .select2-selection--single, .select2-selection--multiple {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa;
  }
  .select2-search--inline .select2-search__field {
    background: transparent;
    border: none;
    outline: 0;
    box-shadow: none;
    -webkit-appearance: textfield;
  }
  .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
  }
  .select2-results__option {
    &[role=group] {
      padding: 0;
    }
    &[aria-disabled=true] {
      color: #999;
    }
    &[aria-selected=true] {
      background-color: #ddd;
    }
    .select2-results__option {
      padding-left: 1em;
      .select2-results__group {
        padding-left: 0;
      }
      .select2-results__option {
        margin-left: -1em;
        padding-left: 2em;
        .select2-results__option {
          margin-left: -2em;
          padding-left: 3em;
          .select2-results__option {
            margin-left: -3em;
            padding-left: 4em;
            .select2-results__option {
              margin-left: -4em;
              padding-left: 5em;
              .select2-results__option {
                margin-left: -5em;
                padding-left: 6em;
              }
            }
          }
        }
      }
    }
  }
  .select2-results__option--highlighted[aria-selected] {
    background-color: #5897fb;
    color: white;
  }
  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }
}

.select2-container--classic {
  .select2-selection--single {
    background-color: #f7f7f7;
    border: 1px solid #aaa;
    border-radius: 4px;
    outline: 0;
    background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
    background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
    background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
    &:focus {
      border: 1px solid #5897fb;
    }
    .select2-selection__rendered {
      color: #444;
      line-height: 28px;
    }
    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
      margin-right: 10px;
    }
    .select2-selection__placeholder {
      color: #999;
    }
    .select2-selection__arrow {
      background-color: #ddd;
      border: none;
      border-left: 1px solid #aaa;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      height: 26px;
      position: absolute;
      top: 1px;
      right: 1px;
      width: 20px;
      background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
      background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
      background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);
      b {
        border-color: #888 transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        height: 0;
        left: 50%;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        width: 0;
      }
    }
  }
  &[dir="rtl"] .select2-selection--single {
    .select2-selection__clear {
      float: left;
    }
    .select2-selection__arrow {
      border: none;
      border-right: 1px solid #aaa;
      border-radius: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      left: 1px;
      right: auto;
    }
  }
  &.select2-container--open {
    .select2-selection--single {
      border: 1px solid #5897fb;
      .select2-selection__arrow {
        background: transparent;
        border: none;
        b {
          border-color: transparent transparent #888 transparent;
          border-width: 0 4px 5px 4px;
        }
      }
    }
    &.select2-container--above .select2-selection--single {
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
      background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
      background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
    }
    &.select2-container--below .select2-selection--single {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
      background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
      background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
    }
  }
  .select2-selection--multiple {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;
    outline: 0;
    &:focus {
      border: 1px solid #5897fb;
    }
    .select2-selection__rendered {
      list-style: none;
      margin: 0;
      padding: 0 5px;
    }
    .select2-selection__clear {
      display: none;
    }
    .select2-selection__choice {
      background-color: #e4e4e4;
      border: 1px solid #aaa;
      border-radius: 4px;
      cursor: default;
      float: left;
      margin-right: 5px;
      margin-top: 5px;
      padding: 0 5px;
    }
    .select2-selection__choice__remove {
      color: #888;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      margin-right: 2px;
      &:hover {
        color: #555;
      }
    }
  }
  &[dir="rtl"] .select2-selection--multiple {
    .select2-selection__choice {
      float: right;
      margin-left: 5px;
      margin-right: auto;
    }
    .select2-selection__choice__remove {
      margin-left: 2px;
      margin-right: auto;
    }
  }
  &.select2-container--open {
    .select2-selection--multiple {
      border: 1px solid #5897fb;
    }
    &.select2-container--above .select2-selection--multiple {
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    &.select2-container--below .select2-selection--multiple {
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa;
    outline: 0;
  }
  .select2-search--inline .select2-search__field {
    outline: 0;
    box-shadow: none;
  }
  .select2-dropdown {
    background-color: white;
    border: 1px solid transparent;
  }
  .select2-dropdown--above {
    border-bottom: none;
  }
  .select2-dropdown--below {
    border-top: none;
  }
  .select2-results > .select2-results__options {
    max-height: 200px;
    overflow-y: auto;
  }
  .select2-results__option {
    &[role=group] {
      padding: 0;
    }
    &[aria-disabled=true] {
      color: grey;
    }
  }
  .select2-results__option--highlighted[aria-selected] {
    background-color: #3875d7;
    color: white;
  }
  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }
  &.select2-container--open .select2-dropdown {
    border-color: #5897fb;
  }
}

/* Smart Forms Adjustments 
----------------------------------------------------------------- */

.select2-container .select2-selection--single {
  height: 42px;
}

.select2-container--default {
  .select2-selection--single {
    .select2-selection__arrow {
      height: 42px;
    }
    .select2-selection__rendered {
      line-height: 42px;
    }
    .select2-selection__arrow {
      height: 42px;
      right: 6px;
    }
  }
  &[dir="rtl"] .select2-selection--single .select2-selection__arrow {
    left: 6px;
  }
}

.select2-container {
  .select2-selection--single .select2-selection__rendered {
    padding-left: 10px;
    padding-right: 26px;
  }
  &[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 10px;
    padding-left: 26px;
  }
  .select2-selection--multiple {
    min-height: 42px;
  }
}

.select2-dropdown {
  border-radius: 0;
}

.select2-container--default {
  .select2-selection--single, .select2-selection--multiple {
    border-radius: 0;
  }
  .select2-selection--single {
    border: 1px solid #CFCFCF;
  }
  .select2-selection--multiple {
    border: 1px solid #CFCFCF;
    .select2-selection__choice {
      border: 1px solid #CFCFCF;
    }
    .select2-selection__rendered {
      margin: 0;
      padding: 5px 10px;
    }
  }
}

.select2-results__option {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}

.smart-select2 {
  width: 100% !important;
}

/*
========================================================= 
DROPZONEJS PLUGIN
http://www.dropzonejs.com/
========================================================= 
*/

@-webkit-keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30%, 70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}


@-moz-keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30%, 70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}


@keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30%, 70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px);
  }
}


@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}


@-moz-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}


@keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px);
  }

  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
  }
}


@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }

  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}


@-moz-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }

  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}


@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }

  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }

  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}


.dropzone {
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  background: white;
  min-height: 150px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  padding: 20px 20px;
  &.dz-clickable {
    cursor: pointer;
    * {
      cursor: default;
    }
    .dz-message {
      cursor: pointer;
      * {
        cursor: pointer;
      }
    }
  }
  &.dz-started .dz-message {
    display: none;
  }
  &.dz-drag-hover {
    border-style: solid;
    .dz-message {
      opacity: 0.5;
    }
  }
  .dz-message {
    text-align: center;
    margin: 2em 0;
  }
  .dz-preview {
    position: relative;
    display: inline-block;
    vertical-align: top;
    min-height: 100px;
    margin: 16px;
    &:hover {
      z-index: 1000;
      .dz-details {
        opacity: 1;
      }
    }
    &.dz-file-preview {
      .dz-image {
        border-radius: 20px;
        background: #999;
        background: linear-gradient(to bottom, #eee, #ddd);
      }
      .dz-details {
        opacity: 1;
      }
    }
    &.dz-image-preview {
      background: white;
      .dz-details {
        -webkit-transition: opacity 0.2s linear;
        -moz-transition: opacity 0.2s linear;
        -ms-transition: opacity 0.2s linear;
        -o-transition: opacity 0.2s linear;
        transition: opacity 0.2s linear;
      }
    }
    .dz-remove {
      font-size: 14px;
      text-align: center;
      display: block;
      cursor: pointer;
      border: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &:hover .dz-details {
      opacity: 1;
    }
    .dz-details {
      z-index: 20;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      font-size: 13px;
      min-width: 100%;
      max-width: 100%;
      padding: 2em 1em;
      text-align: center;
      color: rgba(0, 0, 0, 0.9);
      line-height: 150%;
      .dz-size {
        margin-bottom: 1em;
        font-size: 16px;
      }
      .dz-filename {
        white-space: nowrap;
        &:hover span {
          border: 1px solid rgba(200, 200, 200, 0.8);
          background-color: rgba(255, 255, 255, 0.8);
        }
        &:not(:hover) {
          overflow: hidden;
          text-overflow: ellipsis;
          span {
            border: 1px solid transparent;
          }
        }
        span {
          background-color: rgba(255, 255, 255, 0.4);
          padding: 0 0.4em;
          border-radius: 3px;
        }
      }
      .dz-size span {
        background-color: rgba(255, 255, 255, 0.4);
        padding: 0 0.4em;
        border-radius: 3px;
      }
    }
    &:hover .dz-image img {
      -webkit-transform: scale(1.05, 1.05);
      -moz-transform: scale(1.05, 1.05);
      -ms-transform: scale(1.05, 1.05);
      -o-transform: scale(1.05, 1.05);
      transform: scale(1.05, 1.05);
      -webkit-filter: blur(8px);
      filter: blur(8px);
    }
    .dz-image {
      border-radius: 20px;
      overflow: hidden;
      width: 120px;
      height: 120px;
      position: relative;
      display: block;
      z-index: 10;
      img {
        display: block;
      }
    }
    &.dz-success .dz-success-mark {
      -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
      -moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
      -ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
      -o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
      animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
    }
    &.dz-error .dz-error-mark {
      opacity: 1;
      -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
      -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
      -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
      -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
      animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
    }
    .dz-success-mark, .dz-error-mark {
      pointer-events: none;
      opacity: 0;
      z-index: 500;
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      margin-left: -27px;
      margin-top: -27px;
    }
    .dz-success-mark svg, .dz-error-mark svg {
      display: block;
      width: 54px;
      height: 54px;
    }
    &.dz-processing .dz-progress {
      opacity: 1;
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      -ms-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      transition: all 0.2s linear;
    }
    &.dz-complete .dz-progress {
      opacity: 0;
      -webkit-transition: opacity 0.4s ease-in;
      -moz-transition: opacity 0.4s ease-in;
      -ms-transition: opacity 0.4s ease-in;
      -o-transition: opacity 0.4s ease-in;
      transition: opacity 0.4s ease-in;
    }
    &:not(.dz-processing) .dz-progress {
      -webkit-animation: pulse 6s ease infinite;
      -moz-animation: pulse 6s ease infinite;
      -ms-animation: pulse 6s ease infinite;
      -o-animation: pulse 6s ease infinite;
      animation: pulse 6s ease infinite;
    }
    .dz-progress {
      opacity: 1;
      z-index: 1000;
      pointer-events: none;
      position: absolute;
      height: 16px;
      left: 50%;
      top: 50%;
      margin-top: -8px;
      width: 80px;
      margin-left: -40px;
      background: rgba(255, 255, 255, 0.9);
      -webkit-transform: scale(1);
      border-radius: 8px;
      overflow: hidden;
      .dz-upload {
        width: 0;
        position: absolute;
        background: #333;
        background: linear-gradient(to bottom, #666, #444);
        -webkit-transition: width 300ms ease-in-out;
        -moz-transition: width 300ms ease-in-out;
        -ms-transition: width 300ms ease-in-out;
        -o-transition: width 300ms ease-in-out;
        transition: width 300ms ease-in-out;
        bottom: 0;
        left: 0;
        top: 0;
      }
    }
    &.dz-error {
      .dz-error-message {
        display: block;
      }
      &:hover .dz-error-message {
        opacity: 1;
        pointer-events: auto;
      }
    }
    .dz-error-message {
      pointer-events: none;
      z-index: 1000;
      position: absolute;
      display: block;
      display: none;
      opacity: 0;
      -webkit-transition: opacity 0.3s ease;
      -moz-transition: opacity 0.3s ease;
      -ms-transition: opacity 0.3s ease;
      -o-transition: opacity 0.3s ease;
      transition: opacity 0.3s ease;
      border-radius: 8px;
      font-size: 13px;
      top: 130px;
      left: -10px;
      width: 140px;
      background: #be2626;
      background: linear-gradient(to bottom, #be2626, #a92222);
      padding: 0.5em 1.2em;
      color: white;
      &:after {
        content: '';
        position: absolute;
        top: -6px;
        left: 64px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #be2626;
      }
    }
    background: #fff;
    -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    -o-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
    padding: 5px;
    .dz-image, .dz-progress {
      border-radius: 0;
    }
    &.dz-file-preview .dz-image {
      border-radius: 0;
      background: #eee;
    }
    .dz-progress {
      .dz-upload {
        background: #4EBD4A;
      }
      opacity: 1;
      z-index: 1000;
      pointer-events: none;
      position: absolute;
      height: 10px;
      left: 5px;
      top: auto;
      bottom: 5px;
      width: 120px;
      margin-top: 0;
      margin-left: 0;
      background: rgba(255, 255, 255, 0.4);
      -webkit-transform: scale(1);
      overflow: hidden;
    }
    .dz-remove {
      display: inline-block;
      width: 32px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 32px;
      background-color: #fff;
      transition: all 0.3s ease;
      text-decoration: none;
      -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
      -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
      -o-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      position: absolute;
      color: #666;
      font-size: 14px;
      z-index: 9999;
      right: -8px;
      top: -8px;
      outline: 0;
      &:hover {
        text-decoration: none;
      }
    }
  }
  display: block;
  padding: 40px 0;
  min-height: 150px;
  background: #F5F5F5;
  border: 2px dashed #CFCFCF;
  border-radius: 0;
  text-align: center;
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  .dz-clicker {
    span {
      display: inline-block;
      background: #4EBD4A;
      padding: 11px 20px;
      border-radius: 4px;
      color: #fff;
      text-align: center;
      pointer-events: none;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-weight: bold;
      vertical-align: top;
      cursor: pointer;
      -webkit-transition: all .2s ease-out;
      transition: all .2s ease-out;
    }
    &:hover span, &:active span {
      background: #3C9B39;
    }
  }
  .dz-drop-title {
    font-weight: 400;
    padding-bottom: 0;
    margin: 0;
  }
  .dz-clicker, .dz-drop-title {
    pointer-events: none;
  }
}

/* dropzone.js adjustments for smartforms v.4
-------------------------------------------------- */

.dz-attachment-er p {
  display: block;
  margin-top: 6px;
  padding: 0 3px;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  line-height: normal;
  font-size: 0.85em;
  color: #DE888A;
}

.dropzone.dz-attachment-n {
  border-color: #DE888A;
}

.dropzone-container .alert {
  display: none !important;
}

/*
========================================================= 
JQUERY TEL INPUT | 
https://intl-tel-input.com/
https://github.com/jackocnr/intl-tel-input
========================================================= 
*/

.intl-tel-input {
  position: relative;
  display: inline-block;
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  .hide {
    display: none;
  }
  .v-hide {
    visibility: hidden;
  }
  input {
    position: relative;
    z-index: 0;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-right: 36px;
    margin-right: 0;
    &[type=text], &[type=tel] {
      position: relative;
      z-index: 0;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      padding-right: 36px;
      margin-right: 0;
    }
  }
  .flag-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 1px;
  }
  .selected-flag {
    z-index: 1;
    position: relative;
    width: 36px;
    height: 100%;
    padding: 0 0 0 8px;
    .iti-flag {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    .iti-arrow {
      position: absolute;
      top: 50%;
      margin-top: -2px;
      right: 6px;
      width: 0;
      height: 0;
      border-left: 3px solid transparent;
      border-right: 3px solid transparent;
      border-top: 4px solid #555;
      &.up {
        border-top: none;
        border-bottom: 4px solid #555;
      }
    }
  }
  .country-list {
    position: absolute;
    z-index: 2;
    list-style: none;
    text-align: left;
    padding: 0;
    margin: 0 0 0 -1px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
    background-color: white;
    border: 1px solid #CCC;
    white-space: nowrap;
    max-height: 200px;
    overflow-y: scroll;
    &.dropup {
      bottom: 100%;
      margin-bottom: -1px;
    }
    .flag-box {
      display: inline-block;
      width: 20px;
    }
    .divider {
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid #CCC;
    }
    .country {
      padding: 5px 10px;
      .dial-code {
        color: #999;
      }
      &.highlight {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
    .flag-box, .country-name, .dial-code {
      vertical-align: middle;
    }
    .flag-box, .country-name {
      margin-right: 6px;
    }
  }
  &.allow-dropdown input {
    padding-right: 6px;
    padding-left: 52px;
    margin-left: 0;
    &[type=text], &[type=tel] {
      padding-right: 6px;
      padding-left: 52px;
      margin-left: 0;
    }
  }
  &.separate-dial-code input {
    padding-right: 6px;
    padding-left: 52px;
    margin-left: 0;
    &[type=text], &[type=tel] {
      padding-right: 6px;
      padding-left: 52px;
      margin-left: 0;
    }
  }
  &.allow-dropdown .flag-container, &.separate-dial-code .flag-container {
    right: auto;
    left: 0;
  }
  &.allow-dropdown .selected-flag, &.separate-dial-code .selected-flag {
    width: 46px;
  }
  &.allow-dropdown {
    .flag-container:hover {
      cursor: pointer;
      .selected-flag {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
    input {
      &[disabled] + .flag-container:hover, &[readonly] + .flag-container:hover {
        cursor: default;
      }
      &[disabled] + .flag-container:hover .selected-flag, &[readonly] + .flag-container:hover .selected-flag {
        background-color: transparent;
      }
    }
  }
  &.separate-dial-code {
    .selected-flag {
      background-color: rgba(0, 0, 0, 0.05);
      display: table;
    }
    .selected-dial-code {
      display: table-cell;
      vertical-align: middle;
      padding-left: 28px;
    }
    &.iti-sdc-2 {
      input {
        padding-left: 66px;
        &[type=text], &[type=tel] {
          padding-left: 66px;
        }
      }
      .selected-flag {
        width: 60px;
      }
    }
    &.allow-dropdown.iti-sdc-2 {
      input {
        padding-left: 76px;
        &[type=text], &[type=tel] {
          padding-left: 76px;
        }
      }
      .selected-flag {
        width: 70px;
      }
    }
    &.iti-sdc-3 {
      input {
        padding-left: 74px;
        &[type=text], &[type=tel] {
          padding-left: 74px;
        }
      }
      .selected-flag {
        width: 68px;
      }
    }
    &.allow-dropdown.iti-sdc-3 {
      input {
        padding-left: 84px;
        &[type=text], &[type=tel] {
          padding-left: 84px;
        }
      }
      .selected-flag {
        width: 78px;
      }
    }
    &.iti-sdc-4 {
      input {
        padding-left: 82px;
        &[type=text], &[type=tel] {
          padding-left: 82px;
        }
      }
      .selected-flag {
        width: 76px;
      }
    }
    &.allow-dropdown.iti-sdc-4 {
      input {
        padding-left: 92px;
        &[type=text], &[type=tel] {
          padding-left: 92px;
        }
      }
      .selected-flag {
        width: 86px;
      }
    }
    &.iti-sdc-5 {
      input {
        padding-left: 90px;
        &[type=text], &[type=tel] {
          padding-left: 90px;
        }
      }
      .selected-flag {
        width: 84px;
      }
    }
    &.allow-dropdown.iti-sdc-5 {
      input {
        padding-left: 100px;
        &[type=text], &[type=tel] {
          padding-left: 100px;
        }
      }
      .selected-flag {
        width: 94px;
      }
    }
  }
  &.iti-container {
    position: absolute;
    top: -1000px;
    left: -1000px;
    z-index: 1060;
    padding: 1px;
    &:hover {
      cursor: pointer;
    }
  }
}

@media (max-width: 500px) {
  .intl-tel-input .country-list {
    white-space: normal;
  }
}

.iti-mobile .intl-tel-input {
  &.iti-container {
    top: 30px;
    bottom: 30px;
    left: 30px;
    right: 30px;
    position: fixed;
  }
  .country-list {
    max-height: 100%;
    width: 100%;
    .country {
      padding: 10px 10px;
      line-height: 1.5em;
    }
  }
}

.iti-flag {
  width: 20px;
  &.be {
    width: 18px;
  }
  &.ch {
    width: 15px;
  }
  &.mc {
    width: 19px;
  }
  &.ne {
    width: 18px;
  }
  &.np {
    width: 13px;
  }
  &.va {
    width: 15px;
  }
  &.ac {
    height: 10px;
    background-position: 0px 0px;
  }
  &.ad {
    height: 14px;
    background-position: -22px 0px;
  }
  &.ae {
    height: 10px;
    background-position: -44px 0px;
  }
  &.af {
    height: 14px;
    background-position: -66px 0px;
  }
  &.ag {
    height: 14px;
    background-position: -88px 0px;
  }
  &.ai {
    height: 10px;
    background-position: -110px 0px;
  }
  &.al {
    height: 15px;
    background-position: -132px 0px;
  }
  &.am {
    height: 10px;
    background-position: -154px 0px;
  }
  &.ao {
    height: 14px;
    background-position: -176px 0px;
  }
  &.aq {
    height: 14px;
    background-position: -198px 0px;
  }
  &.ar {
    height: 13px;
    background-position: -220px 0px;
  }
  &.as {
    height: 10px;
    background-position: -242px 0px;
  }
  &.at {
    height: 14px;
    background-position: -264px 0px;
  }
  &.au {
    height: 10px;
    background-position: -286px 0px;
  }
  &.aw {
    height: 14px;
    background-position: -308px 0px;
  }
  &.ax {
    height: 13px;
    background-position: -330px 0px;
  }
  &.az {
    height: 10px;
    background-position: -352px 0px;
  }
  &.ba {
    height: 10px;
    background-position: -374px 0px;
  }
  &.bb {
    height: 14px;
    background-position: -396px 0px;
  }
  &.bd {
    height: 12px;
    background-position: -418px 0px;
  }
  &.be {
    height: 15px;
    background-position: -440px 0px;
  }
  &.bf {
    height: 14px;
    background-position: -460px 0px;
  }
  &.bg {
    height: 12px;
    background-position: -482px 0px;
  }
  &.bh {
    height: 12px;
    background-position: -504px 0px;
  }
  &.bi {
    height: 12px;
    background-position: -526px 0px;
  }
  &.bj {
    height: 14px;
    background-position: -548px 0px;
  }
  &.bl {
    height: 14px;
    background-position: -570px 0px;
  }
  &.bm {
    height: 10px;
    background-position: -592px 0px;
  }
  &.bn {
    height: 10px;
    background-position: -614px 0px;
  }
  &.bo {
    height: 14px;
    background-position: -636px 0px;
  }
  &.bq {
    height: 14px;
    background-position: -658px 0px;
  }
  &.br {
    height: 14px;
    background-position: -680px 0px;
  }
  &.bs {
    height: 10px;
    background-position: -702px 0px;
  }
  &.bt {
    height: 14px;
    background-position: -724px 0px;
  }
  &.bv {
    height: 15px;
    background-position: -746px 0px;
  }
  &.bw {
    height: 14px;
    background-position: -768px 0px;
  }
  &.by {
    height: 10px;
    background-position: -790px 0px;
  }
  &.bz {
    height: 14px;
    background-position: -812px 0px;
  }
  &.ca {
    height: 10px;
    background-position: -834px 0px;
  }
  &.cc {
    height: 10px;
    background-position: -856px 0px;
  }
  &.cd {
    height: 15px;
    background-position: -878px 0px;
  }
  &.cf {
    height: 14px;
    background-position: -900px 0px;
  }
  &.cg {
    height: 14px;
    background-position: -922px 0px;
  }
  &.ch {
    height: 15px;
    background-position: -944px 0px;
  }
  &.ci {
    height: 14px;
    background-position: -961px 0px;
  }
  &.ck {
    height: 10px;
    background-position: -983px 0px;
  }
  &.cl {
    height: 14px;
    background-position: -1005px 0px;
  }
  &.cm {
    height: 14px;
    background-position: -1027px 0px;
  }
  &.cn {
    height: 14px;
    background-position: -1049px 0px;
  }
  &.co {
    height: 14px;
    background-position: -1071px 0px;
  }
  &.cp {
    height: 14px;
    background-position: -1093px 0px;
  }
  &.cr {
    height: 12px;
    background-position: -1115px 0px;
  }
  &.cu {
    height: 10px;
    background-position: -1137px 0px;
  }
  &.cv {
    height: 12px;
    background-position: -1159px 0px;
  }
  &.cw {
    height: 14px;
    background-position: -1181px 0px;
  }
  &.cx {
    height: 10px;
    background-position: -1203px 0px;
  }
  &.cy {
    height: 13px;
    background-position: -1225px 0px;
  }
  &.cz {
    height: 14px;
    background-position: -1247px 0px;
  }
  &.de {
    height: 12px;
    background-position: -1269px 0px;
  }
  &.dg {
    height: 10px;
    background-position: -1291px 0px;
  }
  &.dj {
    height: 14px;
    background-position: -1313px 0px;
  }
  &.dk {
    height: 15px;
    background-position: -1335px 0px;
  }
  &.dm {
    height: 10px;
    background-position: -1357px 0px;
  }
  &.do {
    height: 13px;
    background-position: -1379px 0px;
  }
  &.dz {
    height: 14px;
    background-position: -1401px 0px;
  }
  &.ea {
    height: 14px;
    background-position: -1423px 0px;
  }
  &.ec {
    height: 14px;
    background-position: -1445px 0px;
  }
  &.ee {
    height: 13px;
    background-position: -1467px 0px;
  }
  &.eg {
    height: 14px;
    background-position: -1489px 0px;
  }
  &.eh {
    height: 10px;
    background-position: -1511px 0px;
  }
  &.er {
    height: 10px;
    background-position: -1533px 0px;
  }
  &.es {
    height: 14px;
    background-position: -1555px 0px;
  }
  &.et {
    height: 10px;
    background-position: -1577px 0px;
  }
  &.eu {
    height: 14px;
    background-position: -1599px 0px;
  }
  &.fi {
    height: 12px;
    background-position: -1621px 0px;
  }
  &.fj {
    height: 10px;
    background-position: -1643px 0px;
  }
  &.fk {
    height: 10px;
    background-position: -1665px 0px;
  }
  &.fm {
    height: 11px;
    background-position: -1687px 0px;
  }
  &.fo {
    height: 15px;
    background-position: -1709px 0px;
  }
  &.fr {
    height: 14px;
    background-position: -1731px 0px;
  }
  &.ga {
    height: 15px;
    background-position: -1753px 0px;
  }
  &.gb {
    height: 10px;
    background-position: -1775px 0px;
  }
  &.gd {
    height: 12px;
    background-position: -1797px 0px;
  }
  &.ge {
    height: 14px;
    background-position: -1819px 0px;
  }
  &.gf {
    height: 14px;
    background-position: -1841px 0px;
  }
  &.gg {
    height: 14px;
    background-position: -1863px 0px;
  }
  &.gh {
    height: 14px;
    background-position: -1885px 0px;
  }
  &.gi {
    height: 10px;
    background-position: -1907px 0px;
  }
  &.gl {
    height: 14px;
    background-position: -1929px 0px;
  }
  &.gm {
    height: 14px;
    background-position: -1951px 0px;
  }
  &.gn {
    height: 14px;
    background-position: -1973px 0px;
  }
  &.gp {
    height: 14px;
    background-position: -1995px 0px;
  }
  &.gq {
    height: 14px;
    background-position: -2017px 0px;
  }
  &.gr {
    height: 14px;
    background-position: -2039px 0px;
  }
  &.gs {
    height: 10px;
    background-position: -2061px 0px;
  }
  &.gt {
    height: 13px;
    background-position: -2083px 0px;
  }
  &.gu {
    height: 11px;
    background-position: -2105px 0px;
  }
  &.gw {
    height: 10px;
    background-position: -2127px 0px;
  }
  &.gy {
    height: 12px;
    background-position: -2149px 0px;
  }
  &.hk {
    height: 14px;
    background-position: -2171px 0px;
  }
  &.hm {
    height: 10px;
    background-position: -2193px 0px;
  }
  &.hn {
    height: 10px;
    background-position: -2215px 0px;
  }
  &.hr {
    height: 10px;
    background-position: -2237px 0px;
  }
  &.ht {
    height: 12px;
    background-position: -2259px 0px;
  }
  &.hu {
    height: 10px;
    background-position: -2281px 0px;
  }
  &.ic {
    height: 14px;
    background-position: -2303px 0px;
  }
  &.id {
    height: 14px;
    background-position: -2325px 0px;
  }
  &.ie {
    height: 10px;
    background-position: -2347px 0px;
  }
  &.il {
    height: 15px;
    background-position: -2369px 0px;
  }
  &.im {
    height: 10px;
    background-position: -2391px 0px;
  }
  &.in {
    height: 14px;
    background-position: -2413px 0px;
  }
  &.io {
    height: 10px;
    background-position: -2435px 0px;
  }
  &.iq {
    height: 14px;
    background-position: -2457px 0px;
  }
  &.ir {
    height: 12px;
    background-position: -2479px 0px;
  }
  &.is {
    height: 15px;
    background-position: -2501px 0px;
  }
  &.it {
    height: 14px;
    background-position: -2523px 0px;
  }
  &.je {
    height: 12px;
    background-position: -2545px 0px;
  }
  &.jm {
    height: 10px;
    background-position: -2567px 0px;
  }
  &.jo {
    height: 10px;
    background-position: -2589px 0px;
  }
  &.jp {
    height: 14px;
    background-position: -2611px 0px;
  }
  &.ke {
    height: 14px;
    background-position: -2633px 0px;
  }
  &.kg {
    height: 12px;
    background-position: -2655px 0px;
  }
  &.kh {
    height: 13px;
    background-position: -2677px 0px;
  }
  &.ki {
    height: 10px;
    background-position: -2699px 0px;
  }
  &.km {
    height: 12px;
    background-position: -2721px 0px;
  }
  &.kn {
    height: 14px;
    background-position: -2743px 0px;
  }
  &.kp {
    height: 10px;
    background-position: -2765px 0px;
  }
  &.kr {
    height: 14px;
    background-position: -2787px 0px;
  }
  &.kw {
    height: 10px;
    background-position: -2809px 0px;
  }
  &.ky {
    height: 10px;
    background-position: -2831px 0px;
  }
  &.kz {
    height: 10px;
    background-position: -2853px 0px;
  }
  &.la {
    height: 14px;
    background-position: -2875px 0px;
  }
  &.lb {
    height: 14px;
    background-position: -2897px 0px;
  }
  &.lc {
    height: 10px;
    background-position: -2919px 0px;
  }
  &.li {
    height: 12px;
    background-position: -2941px 0px;
  }
  &.lk {
    height: 10px;
    background-position: -2963px 0px;
  }
  &.lr {
    height: 11px;
    background-position: -2985px 0px;
  }
  &.ls {
    height: 14px;
    background-position: -3007px 0px;
  }
  &.lt {
    height: 12px;
    background-position: -3029px 0px;
  }
  &.lu {
    height: 12px;
    background-position: -3051px 0px;
  }
  &.lv {
    height: 10px;
    background-position: -3073px 0px;
  }
  &.ly {
    height: 10px;
    background-position: -3095px 0px;
  }
  &.ma {
    height: 14px;
    background-position: -3117px 0px;
  }
  &.mc {
    height: 15px;
    background-position: -3139px 0px;
  }
  &.md {
    height: 10px;
    background-position: -3160px 0px;
  }
  &.me {
    height: 10px;
    background-position: -3182px 0px;
  }
  &.mf {
    height: 14px;
    background-position: -3204px 0px;
  }
  &.mg {
    height: 14px;
    background-position: -3226px 0px;
  }
  &.mh {
    height: 11px;
    background-position: -3248px 0px;
  }
  &.mk {
    height: 10px;
    background-position: -3270px 0px;
  }
  &.ml {
    height: 14px;
    background-position: -3292px 0px;
  }
  &.mm {
    height: 14px;
    background-position: -3314px 0px;
  }
  &.mn {
    height: 10px;
    background-position: -3336px 0px;
  }
  &.mo {
    height: 14px;
    background-position: -3358px 0px;
  }
  &.mp {
    height: 10px;
    background-position: -3380px 0px;
  }
  &.mq {
    height: 14px;
    background-position: -3402px 0px;
  }
  &.mr {
    height: 14px;
    background-position: -3424px 0px;
  }
  &.ms {
    height: 10px;
    background-position: -3446px 0px;
  }
  &.mt {
    height: 14px;
    background-position: -3468px 0px;
  }
  &.mu {
    height: 14px;
    background-position: -3490px 0px;
  }
  &.mv {
    height: 14px;
    background-position: -3512px 0px;
  }
  &.mw {
    height: 14px;
    background-position: -3534px 0px;
  }
  &.mx {
    height: 12px;
    background-position: -3556px 0px;
  }
  &.my {
    height: 10px;
    background-position: -3578px 0px;
  }
  &.mz {
    height: 14px;
    background-position: -3600px 0px;
  }
  &.na {
    height: 14px;
    background-position: -3622px 0px;
  }
  &.nc {
    height: 10px;
    background-position: -3644px 0px;
  }
  &.ne {
    height: 15px;
    background-position: -3666px 0px;
  }
  &.nf {
    height: 10px;
    background-position: -3686px 0px;
  }
  &.ng {
    height: 10px;
    background-position: -3708px 0px;
  }
  &.ni {
    height: 12px;
    background-position: -3730px 0px;
  }
  &.nl {
    height: 14px;
    background-position: -3752px 0px;
  }
  &.no {
    height: 15px;
    background-position: -3774px 0px;
  }
  &.np {
    height: 15px;
    background-position: -3796px 0px;
  }
  &.nr {
    height: 10px;
    background-position: -3811px 0px;
  }
  &.nu {
    height: 10px;
    background-position: -3833px 0px;
  }
  &.nz {
    height: 10px;
    background-position: -3855px 0px;
  }
  &.om {
    height: 10px;
    background-position: -3877px 0px;
  }
  &.pa {
    height: 14px;
    background-position: -3899px 0px;
  }
  &.pe {
    height: 14px;
    background-position: -3921px 0px;
  }
  &.pf {
    height: 14px;
    background-position: -3943px 0px;
  }
  &.pg {
    height: 15px;
    background-position: -3965px 0px;
  }
  &.ph {
    height: 10px;
    background-position: -3987px 0px;
  }
  &.pk {
    height: 14px;
    background-position: -4009px 0px;
  }
  &.pl {
    height: 13px;
    background-position: -4031px 0px;
  }
  &.pm {
    height: 14px;
    background-position: -4053px 0px;
  }
  &.pn {
    height: 10px;
    background-position: -4075px 0px;
  }
  &.pr {
    height: 14px;
    background-position: -4097px 0px;
  }
  &.ps {
    height: 10px;
    background-position: -4119px 0px;
  }
  &.pt {
    height: 14px;
    background-position: -4141px 0px;
  }
  &.pw {
    height: 13px;
    background-position: -4163px 0px;
  }
  &.py {
    height: 11px;
    background-position: -4185px 0px;
  }
  &.qa {
    height: 8px;
    background-position: -4207px 0px;
  }
  &.re {
    height: 14px;
    background-position: -4229px 0px;
  }
  &.ro {
    height: 14px;
    background-position: -4251px 0px;
  }
  &.rs {
    height: 14px;
    background-position: -4273px 0px;
  }
  &.ru {
    height: 14px;
    background-position: -4295px 0px;
  }
  &.rw {
    height: 14px;
    background-position: -4317px 0px;
  }
  &.sa {
    height: 14px;
    background-position: -4339px 0px;
  }
  &.sb {
    height: 10px;
    background-position: -4361px 0px;
  }
  &.sc {
    height: 10px;
    background-position: -4383px 0px;
  }
  &.sd {
    height: 10px;
    background-position: -4405px 0px;
  }
  &.se {
    height: 13px;
    background-position: -4427px 0px;
  }
  &.sg {
    height: 14px;
    background-position: -4449px 0px;
  }
  &.sh {
    height: 10px;
    background-position: -4471px 0px;
  }
  &.si {
    height: 10px;
    background-position: -4493px 0px;
  }
  &.sj {
    height: 15px;
    background-position: -4515px 0px;
  }
  &.sk {
    height: 14px;
    background-position: -4537px 0px;
  }
  &.sl {
    height: 14px;
    background-position: -4559px 0px;
  }
  &.sm {
    height: 15px;
    background-position: -4581px 0px;
  }
  &.sn {
    height: 14px;
    background-position: -4603px 0px;
  }
  &.so {
    height: 14px;
    background-position: -4625px 0px;
  }
  &.sr {
    height: 14px;
    background-position: -4647px 0px;
  }
  &.ss {
    height: 10px;
    background-position: -4669px 0px;
  }
  &.st {
    height: 10px;
    background-position: -4691px 0px;
  }
  &.sv {
    height: 12px;
    background-position: -4713px 0px;
  }
  &.sx {
    height: 14px;
    background-position: -4735px 0px;
  }
  &.sy {
    height: 14px;
    background-position: -4757px 0px;
  }
  &.sz {
    height: 14px;
    background-position: -4779px 0px;
  }
  &.ta {
    height: 10px;
    background-position: -4801px 0px;
  }
  &.tc {
    height: 10px;
    background-position: -4823px 0px;
  }
  &.td {
    height: 14px;
    background-position: -4845px 0px;
  }
  &.tf {
    height: 14px;
    background-position: -4867px 0px;
  }
  &.tg {
    height: 13px;
    background-position: -4889px 0px;
  }
  &.th {
    height: 14px;
    background-position: -4911px 0px;
  }
  &.tj {
    height: 10px;
    background-position: -4933px 0px;
  }
  &.tk {
    height: 10px;
    background-position: -4955px 0px;
  }
  &.tl {
    height: 10px;
    background-position: -4977px 0px;
  }
  &.tm {
    height: 14px;
    background-position: -4999px 0px;
  }
  &.tn {
    height: 14px;
    background-position: -5021px 0px;
  }
  &.to {
    height: 10px;
    background-position: -5043px 0px;
  }
  &.tr {
    height: 14px;
    background-position: -5065px 0px;
  }
  &.tt {
    height: 12px;
    background-position: -5087px 0px;
  }
  &.tv {
    height: 10px;
    background-position: -5109px 0px;
  }
  &.tw {
    height: 14px;
    background-position: -5131px 0px;
  }
  &.tz {
    height: 14px;
    background-position: -5153px 0px;
  }
  &.ua {
    height: 14px;
    background-position: -5175px 0px;
  }
  &.ug {
    height: 14px;
    background-position: -5197px 0px;
  }
  &.um {
    height: 11px;
    background-position: -5219px 0px;
  }
  &.us {
    height: 11px;
    background-position: -5241px 0px;
  }
  &.uy {
    height: 14px;
    background-position: -5263px 0px;
  }
  &.uz {
    height: 10px;
    background-position: -5285px 0px;
  }
  &.va {
    height: 15px;
    background-position: -5307px 0px;
  }
  &.vc {
    height: 14px;
    background-position: -5324px 0px;
  }
  &.ve {
    height: 14px;
    background-position: -5346px 0px;
  }
  &.vg {
    height: 10px;
    background-position: -5368px 0px;
  }
  &.vi {
    height: 14px;
    background-position: -5390px 0px;
  }
  &.vn {
    height: 14px;
    background-position: -5412px 0px;
  }
  &.vu {
    height: 12px;
    background-position: -5434px 0px;
  }
  &.wf {
    height: 14px;
    background-position: -5456px 0px;
  }
  &.ws {
    height: 10px;
    background-position: -5478px 0px;
  }
  &.xk {
    height: 15px;
    background-position: -5500px 0px;
  }
  &.ye {
    height: 14px;
    background-position: -5522px 0px;
  }
  &.yt {
    height: 14px;
    background-position: -5544px 0px;
  }
  &.za {
    height: 14px;
    background-position: -5566px 0px;
  }
  &.zm {
    height: 14px;
    background-position: -5588px 0px;
  }
  &.zw {
    height: 10px;
    background-position: -5610px 0px;
  }
  width: 20px;
  height: 15px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: url("images/flags.png");
  background-repeat: no-repeat;
  background-color: #DBDBDB;
  background-position: 20px 0;
  &.np {
    background-color: transparent;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .iti-flag {
    background-size: 5630px 15px;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .iti-flag {
    background-image: url("images/flags@2x.png");
  }
}

/*
--------------------------------------------- 
Adjustments for Smart Forms 
---------------------------------------------*/

.intl-tel-input {
  width: 100%;
}

.telSelect {
  z-index: 1 !important;
}

.telErrorMsg, .telValidMsg {
  display: block;
  margin-top: 6px;
  padding: 0 3px;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  line-height: normal;
  font-size: 0.85em;
}

.telErrorMsg {
  color: #DE888A;
}

.telValidMsg {
  color: #00C900;
}

.gui-input {
  &.telErrorNum {
    background: #FEE9EA;
    border-color: #DE888A;
    &:hover {
      background: #FEE9EA;
      border-color: #DE888A;
    }
    &:focus {
      background: #FEE9EA;
      border-color: #DE888A;
      -webkit-box-shadow: 0px 0px 3px #DE888A inset;
      -moz-box-shadow: 0px 0px 3px #DE888A inset;
      -o-box-shadow: 0px 0px 3px #DE888A inset;
      box-shadow: 0px 0px 3px #DE888A inset;
    }
  }
  &.telValidNum {
    background: #F0FEE9;
    border-color: #A5D491;
    &:hover {
      background: #F0FEE9;
      border-color: #A5D491;
    }
    &:focus {
      background: #F0FEE9;
      border-color: #A5D491;
      -webkit-box-shadow: 0px 0px 3px #A5D491 inset;
      -moz-box-shadow: 0px 0px 3px #A5D491 inset;
      -o-box-shadow: 0px 0px 3px #A5D491 inset;
      box-shadow: 0px 0px 3px #A5D491 inset;
    }
  }
}

.intFormat {
  color: #DE888A;
}

.telHideMsg {
  display: none;
}

/*
--------------------------------------------------
Overide container border-radius 
-------------------------------------------------*/

.smart-container {
  -webkit-border-radius: 5px 5px 0 0;
  -moz-border-radius: 5px 5px 0 0;
  -o-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}

@media (max-width: 800px) {
  .wizard > .steps > ul > li {
    width: 100%;
  }
  .smart-steps .wizard {
    > .steps {
      > ul > li {
        width: 100%;
      }
      a {
        &:after, &:before {
          display: none !important;
        }
      }
    }
    &.vertical > {
      .steps {
        display: block;
        float: none;
        width: 100%;
      }
      .content {
        display: block;
        float: none;
        width: 100%;
        padding: 0;
        margin: 0;
        padding-right: 5px;
      }
    }
  }
  .steps-progress .wizard > .steps {
    overflow: visible;
    padding-bottom: 10px;
    .number, li:before {
      display: none;
    }
  }
}

@media (max-width: 400px) {
  .smart-steps .wizard > .actions {
    a, li, > ul {
      display: block;
    }
    a {
      margin-bottom: 5px;
    }
  }
}