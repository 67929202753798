/*	
--------------------------------------------------
@ * Smart Forms v4
  * http://www.doptiq.com/smart-forms
  * Last updated 01 / JUNE / 2017
  * Product developed by Elflaire 
  * http://codecanyon.net/user/elflaire
-------------------------------------------------- 
*/


/* Roboto google font import 
--------------------------------------- */

@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,700,700i,900');
html,
body {
    border: 0;
    margin: 0;
    padding: 0;
}


/* @backgrounds :: modify or add yours below 
------------------------------------------------------------------- */

.darkbg {
    background: #6C82A2 url(images/dark.png) repeat fixed;
}

.woodbg {
    background: #E6CCA6 url(images/wood.png) repeat fixed;
}


/* @form wrappers 
---------------------------------- */

.smart-wrap {
    padding: 0 20px;
    background: none;
}

.smart-forms {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    * {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    font-family: "Roboto",
    Arial,
    Helvetica,
    sans-serif;
    line-height: 1.231;
    font-weight: 400;
    font-size: 16px;
    color: #626262;
}

.smart-container {
    background: #fff;
    margin: 50px auto;
    -webkit-border-radius: 6px 6px 0 0;
    -moz-border-radius: 6px 6px 0 0;
    -o-border-radius: 6px 6px 0 0;
    border-radius: 6px 6px 0 0;
}


/* @form container width 
/* @if you want to change the form container width change the values below 
/* @alternatively you can add yours eg .wrap4{ max-width:200px; } 
---------------------------------------------------------------------------- */

.wrap-0 {
    max-width: 952px;
}

.wrap-1 {
    max-width: 852px;
}

.wrap-2 {
    max-width: 652px;
}

.wrap-3 {
    max-width: 452px;
}


/* @form helper classes 
--------------------------------------------------------------- */

.smart-forms {
    .section {
        margin-bottom: 22px;
    }
    .smart-link {
        color: #4ebd4a;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .tagline {
        height: 0;
        border-top: 1px solid #CFCFCF;
        text-align: center;
        span {
            text-transform: uppercase;
            display: inline-block;
            position: relative;
            padding: 0 15px;
            background: #fff;
            color: #4ebd4a;
            top: -10px;
        }
    }
    .field {
        display: block;
        position: relative;
    }
    .field-icon i {
        color: #BBB;
        position: relative;
    }
    .field-label {
        display: block;
        margin-bottom: 7px;
        &.colm {
            padding-top: 12px;
        }
        em {
            color: #e74c3c;
            font-size: 16px;
            font-style: normal;
            display: inline-block;
            margin-left: 4px;
            position: relative;
            top: 3px;
        }
    }
    fieldset {
        border: 0;
        display: block;
    }
    .form-header {
        overflow: hidden;
        position: relative;
        padding: 25px 30px;
        -webkit-border-radius: 5px 5px 0 0;
        -moz-border-radius: 5px 5px 0 0;
        -o-border-radius: 5px 5px 0 0;
        border-radius: 5px 5px 0 0;
        h4 {
            font-family: "Roboto", Arial, Helvetica, sans-serif;
            text-align: left;
            font-weight: 300;
            font-size: 32px;
            padding: 0;
            margin: 0;
            i {
                font-size: 38px;
                position: relative;
                margin-right: 10px;
                top: 2px;
            }
        }
    }
    .header-primary {
        background-color: #4ebd4a;
        border-top: 1px solid #A7D065;
        border-bottom: 5px solid #3c9b39;
    }
    .header-lite {
        background: #F3F5FA;
        border-top: 1px solid #A7D065;
        border-bottom: 1px solid #D9DDE5;
        &:before {
            content: "";
            background-color: #4ebd4a;
            position: absolute;
            height: 8px;
            z-index: 1;
            top: 0px;
            right: 0;
            left: 0;
        }
    }
    .header-primary h4 {
        color: #fff;
    }
    .header-lite h4 {
        color: #5D6A87;
        padding-top: 5px;
    }
}


/* @form label + field :: field class is useful for validation 
---------------------------------------------------------------------- */


/* @form header section 
----------------------------------------- */


/* @header themes :: primary + lite 
---------------------------------------------- */


/* @remove rounded corners form headers 
----------------------------------------------------------------- */

.smart-flat {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
    border-radius: 0;
    .form-header {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;
    }
}


/* @form body + footer 
------------------------------------------------------------------- */

.smart-forms {
    .form-body {
        padding: 40px 30px;
        padding-bottom: 20px;
    }
    .form-footer {
        overflow: hidden;
        padding: 20px 25px;
        padding-top: 25px;
        background: #F5F5F5;
        background: #F5F5F5 url(images/foobg.png) top left repeat-x;
    }
    input[type=search] {
        -webkit-appearance: textfield;
    }
     ::-webkit-search-decoration,
     ::-webkit-search-cancel-button {
        -webkit-appearance: none;
    }
    input {
        &:invalid {
            -moz-box-shadow: none;
            box-shadow: none;
        }
        &::-webkit-input-placeholder {
            color: #AAAAAA;
        }
    }
    textarea::-webkit-input-placeholder {
        color: #AAAAAA;
    }
    input:focus::-webkit-input-placeholder,
    textarea:focus::-webkit-input-placeholder {
        color: #D6DBE0;
    }
    input:-moz-placeholder,
    textarea:-moz-placeholder {
        color: #AAAAAA;
    }
    input:focus:-moz-placeholder,
    textarea:focus:-moz-placeholder {
        color: #D6DBE0;
    }
    input::-moz-placeholder,
    textarea::-moz-placeholder {
        color: #AAAAAA;
        opacity: 1;
    }
    input:focus::-moz-placeholder,
    textarea:focus::-moz-placeholder {
        color: #D6DBE0;
        opacity: 1;
    }
    input:-ms-input-placeholder,
    textarea:-ms-input-placeholder {
        color: #AAAAAA;
    }
    input:focus:-ms-input-placeholder,
    textarea:focus:-ms-input-placeholder {
        color: #D6DBE0;
    }
    label,
    input,
    button,
    select,
    textarea {
        margin: 0;
        font-size: 14px;
        font-family: "Roboto", Arial, Helvetica, sans-serif;
        font-weight: 400;
        color: #626262;
        outline: none;
    }
    .gui-input,
    .gui-textarea,
    .select>select {
        -webkit-tap-highlight-color: transparent;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -webkit-border-radius: 0px;
        border-radius: 0px;
    }
    input {
        &[type="button"],
        &[type="submit"],
        &[type="search"] {
            -webkit-tap-highlight-color: transparent;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            -webkit-border-radius: 0px;
            border-radius: 0px;
        }
    }
    .select-multiple select {
        -webkit-tap-highlight-color: transparent;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -webkit-border-radius: 0px;
        border-radius: 0px;
    }
    input[type="search"] {
        &::-webkit-search-decoration,
        &::-webkit-search-cancel-button,
        &::-webkit-search-results-button,
        &::-webkit-search-results-decoration {
            display: none;
        }
    }
    .switch,
    .option,
    .field-label {
        font-size: 16px;
    }
    .radio,
    .button,
    .checkbox,
    .select .arrow,
    .switch>label,
    .ui-slider .ui-slider-handle {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .radio,
    .button,
    .tooltip,
    .checkbox,
    .gui-input,
    .notification,
    .gui-textarea,
    .select>select,
    .select-multiple select {
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -ms-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;
        outline: none;
    }
    .select>select,
    .select-multiple select {
        -webkit-transition: none;
        -moz-transition: none;
        -ms-transition: none;
        -o-transition: none;
        transition: none;
    }
    .select,
    .gui-input,
    .gui-textarea,
    .select>select,
    .select-multiple select {
        background: #fff;
        position: relative;
        vertical-align: top;
        border: 1px solid #CFCFCF;
        display: -moz-inline-stack;
        display: inline-block;
        *display: inline;
        color: #626262;
        outline: none;
        height: 42px;
        width: 100%;
        *zoom: 1;
    }
    .gui-input {
        padding: 10px;
    }
    .gui-textarea {
        padding: 10px;
        resize: none;
        line-height: 19px;
        overflow: auto;
        max-width: 100%;
        height: 96px;
    }
    .input-hint {
        padding: 10px;
        display: block;
        margin-top: -1px;
        line-height: 16px;
        position: relative;
        background: #F5F5F5;
        border: 1px solid #CFCFCF;
        font-family: Arial, Helvetica, sans-serif;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        -o-border-radius: 0;
        border-radius: 0;
        font-size: 11px;
        color: #999;
    }
    .select {
        border: 0;
        z-index: 10;
        >select {
            display: block;
            padding: 9px 10px;
            color: #626262;
            background: #F5F5F5;
            border: 1px solid #CFCFCF;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: normal;
            outline: none;
            text-indent: 0.01px;
            text-overflow: '';
            z-index: 10;
            margin: 0;
            &::-ms-expand {
                display: none;
            }
        }
        .arrow {
            position: absolute;
            top: 9px;
            right: 4px;
            width: 24px;
            height: 24px;
            color: #9F9F9F;
            pointer-events: none;
            z-index: 16;
            &:after {
                content: '';
                position: absolute;
                font: 12px "Consolas", monospace;
                font-style: normal;
                pointer-events: none;
                display: none\9;
                left: 5px;
            }
            &:before {
                content: '';
                position: absolute;
                font: 12px "Consolas", monospace;
                font-style: normal;
                pointer-events: none;
                display: none\9;
                left: 5px;
                content: '\25BC';
                bottom: 4px;
            }
        }
        .double {
            &:after {
                content: '\25B2';
                top: -1px;
            }
            &:before {
                content: '\25BC';
                bottom: -1px;
            }
        }
    }
    .select-multiple select {
        width: 100%;
        height: 123px;
        padding: 10px;
    }
    .file {
        display: block;
        width: 100%;
        .gui-file {
            width: 100%;
            height: 100%;
            cursor: pointer;
            padding: 8px 10px;
            position: absolute;
            -moz-opacity: 0;
            opacity: 0;
            z-index: 11;
            bottom: 0;
            right: 0;
        }
        .button {
            position: absolute;
            top: 4px;
            right: 4px;
            float: none;
            height: 34px;
            line-height: 34px;
            padding: 0 16px;
            z-index: 10;
        }
    }
    .gui-input:hover,
    .gui-textarea:hover,
    .select>select:hover,
    .select-multiple select:hover,
    .gui-input:hover~.input-hint,
    .file .gui-file:hover+.gui-input,
    .gui-textarea:hover~.input-hint {
        border-color: #6BC868;
    }
    .gui-input:focus,
    .gui-textarea:focus,
    .select>select:focus,
    .select-multiple select:focus {
        color: #3c3c3c;
        background: #fff;
        border: 1px solid #4EBD4A;
        -webkit-box-shadow: 0px 0px 3px #4EBD4A inset;
        -moz-box-shadow: 0px 0px 3px #4EBD4A inset;
        -o-box-shadow: 0px 0px 3px #4EBD4A inset;
        box-shadow: 0px 0px 3px #4EBD4A inset;
        outline: none;
    }
    .select>select:focus {
        z-index: 10;
        z-index: 20\9;
    }
    .gui-textarea:focus {
        height: 120px;
    }
    .select>select:focus {
        z-index: 10;
        z-index: 20\9;
    }
    .gui-input:focus~.field-icon i,
    .gui-textarea:focus~.field-icon i {
        color: #4EBD4A;
    }
    .select-multiple select:focus,
    .gui-input:focus~.input-hint,
    .gui-textarea:focus~.input-hint,
    .file .gui-file:focus+.gui-input {
        border-color: #4EBD4A;
    }
    .select>select:focus+.arrow {
        color: #4EBD4A;
    }
    .option {
        position: relative;
        padding-right: 15px;
        display: inline-block;
        vertical-align: middle;
        >input {
            position: absolute;
            height: inherit;
            width: inherit;
            opacity: 0;
            left: 0;
        }
    }
    .checkbox,
    .radio {
        position: relative;
        margin-right: 2px;
        background: #fff;
        display: inline-block;
        border: 3px solid #CFCFCF;
        margin-bottom: 0;
        margin-top: 0;
        height: 21px;
        width: 21px;
        top: 4px;
    }
    .checkbox:before,
    .radio:before {
        content: '';
        display: none;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        -o-box-sizing: content-box;
        box-sizing: content-box;
        padding: 0;
        margin: 0;
    }
    input:checked+ {
        .checkbox:before,
        .radio:before {
            display: block;
        }
    }
    .checkbox:before {
        position: absolute;
        top: 5px;
        left: 4px;
        width: 6px;
        height: 3px;
        border: solid #4ebd4a;
        -webkit-border-width: 0 0 3px 3px;
        -moz-border-width: 0 0 3px 3px;
        border-width: 0 0 3px 3px;
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    input:checked+ {
        .checkbox,
        .radio {
            border: 3px solid #4ebd4a;
        }
    }
    .radio {
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px;
        &:before {
            margin: 4px;
            width: 8px;
            height: 8px;
            background: #4ebd4a;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -o-border-radius: 10px;
            border-radius: 10px;
        }
    }
    input {
        &:hover+ {
            .checkbox,
            .radio {
                border-color: #6BC868;
            }
        }
        &:focus+ {
            .checkbox {
                border-color: #4ebd4a;
            }
            .radio {
                border-color: #4ebd4a;
                &:before {
                    background: #4ebd4a;
                }
            }
            .checkbox:before {
                border-color: #4ebd4a;
            }
        }
    }
    .switch {
        cursor: pointer;
        position: relative;
        padding-right: 10px;
        display: inline-block;
        margin-bottom: 5px;
        height: 26px;
        > {
            .switch-label {
                cursor: pointer;
                display: inline-block;
                position: relative;
                height: 25px;
                width: 58px;
                color: #fff;
                font-size: 10px;
                font-weight: 400;
                line-height: 20px;
                text-align: center;
                background: #D7D7D7;
                border: 2px solid #D7D7D7;
                text-transform: uppercase;
                letter-spacing: 1px;
                -webkit-transition: 0.3s ease-out;
                -moz-transition: 0.3s ease-out;
                -o-transition: 0.3s ease-out;
                transition: 0.3s ease-out;
                +span {
                    display: inline-block;
                    padding-left: 5px;
                    position: relative;
                    top: -7px;
                }
                &:before {
                    content: attr(data-off);
                    position: absolute;
                    top: 1px;
                    right: 3px;
                    width: 33px;
                }
                &:after {
                    content: "";
                    margin: 1px;
                    width: 19px;
                    height: 19px;
                    display: block;
                    background: #fff;
                }
            }
            input {
                -webkit-appearance: none;
                position: absolute;
                width: inherit;
                height: inherit;
                opacity: 0;
                left: 0;
                top: 0;
                &:focus {
                    outline: none;
                    +.switch-label {
                        color: #fff;
                        border-color: #C7C7C7;
                        background: #C7C7C7;
                        &:after {
                            background: #fff;
                        }
                    }
                }
                &:checked {
                    +.switch-label {
                        border-color: #4ebd4a;
                        background: #4ebd4a;
                        padding-left: 33px;
                        color: white;
                        &:before {
                            content: attr(data-on);
                            left: 1px;
                            top: 1px;
                        }
                        &:after {
                            margin: 1px;
                            width: 19px;
                            height: 19px;
                            background: white;
                        }
                    }
                    &:focus+.switch-label {
                        background: #3c9b39;
                        border-color: #3c9b39;
                    }
                }
            }
        }
    }
    .switch-round> {
        .switch-label {
            -webkit-border-radius: 13px;
            -moz-border-radius: 13px;
            -o-border-radius: 13px;
            border-radius: 13px;
            +span {
                top: -2px;
            }
            &:before {
                width: 33px;
            }
            &:after {
                width: 19px;
                height: 19px;
                color: #D7D7D7;
                content: "\2022";
                font: 30px/20px Times, Serif;
                -webkit-border-radius: 20px;
                -moz-border-radius: 20px;
                -o-border-radius: 20px;
                border-radius: 20px;
            }
        }
        input:checked+.switch-label {
            padding-left: 33px;
            &:after {
                color: #4ebd4a;
            }
        }
    }
    .option-group {
        &:before {
            display: table;
            content: "";
            line-height: 0;
        }
        &:after {
            display: table;
            content: "";
            line-height: 0;
            clear: both;
        }
    }
    .smart-option-group .option {
        padding: 0;
        margin: 0;
    }
    .smart-option {
        border: 1px solid #c5c5c5;
        margin-right: 5px;
        background: #F5F5F5;
        font-weight: normal;
        text-decoration: none;
        color: #454545;
        border-radius: 3px;
        padding: 0 20px;
        display: inline-block;
        position: relative;
        line-height: normal;
        cursor: pointer;
        vertical-align: middle;
        text-align: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        overflow: hidden;
        line-height: 40px;
        height: 40px;
    }
    .smart-option-ui {
        display: inline-block;
        .iconc {
            position: relative;
            margin-right: 2px;
            background: #fff;
            display: inline-block;
            border: 3px solid #CFCFCF;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            -o-border-radius: 2px;
            border-radius: 2px;
            margin-bottom: 0;
            margin-top: 0;
            height: 21px;
            width: 21px;
            top: 4px;
        }
    }
    .smart-radio .iconc {
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        -o-border-radius: 20px;
        border-radius: 20px;
    }
    .option>input:checked+.smart-option {
        border-color: #2F8640;
        background: #4ebd4a;
        color: white;
        .fa {
            font-size: 14px;
        }
        .iconc {
            border-color: #2F8640;
        }
    }
    .smart-radio .iconc:before,
    .smart-checkbox .iconc:before {
        content: '';
        display: none;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        -o-box-sizing: content-box;
        box-sizing: content-box;
        padding: 0;
        margin: 0;
    }
    input:checked+ {
        .smart-radio .iconc:before,
        .smart-checkbox .iconc:before {
            display: block;
        }
        .smart-radio .iconc:before {
            margin: 4px;
            width: 8px;
            height: 8px;
            background: #2F8640;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -o-border-radius: 10px;
            border-radius: 10px;
        }
        .smart-checkbox .iconc:before {
            position: absolute;
            top: 5px;
            left: 4px;
            width: 6px;
            height: 3px;
            border: solid #2F8640;
            -webkit-border-width: 0 0 3px 3px;
            -moz-border-width: 0 0 3px 3px;
            border-width: 0 0 3px 3px;
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
    }
    .smart-option-list {
        display: inline-block;
        vertical-align: top;
        white-space: nowrap;
        font-size: 0;
        &.left {
            float: left;
        }
        &.right {
            float: right;
        }
        .smart-option {
            margin-right: 0;
            margin-left: -1px;
            border-radius: 0;
        }
        label {
            &:first-child .smart-option {
                margin-left: 0;
                border-radius: 3px 0 0 3px;
            }
            &:last-child .smart-option {
                border-radius: 0 3px 3px 0;
            }
        }
        .option>input:checked+.smart-option {
            position: relative;
            z-index: 1;
        }
    }
    .group-vertical {
        display: block;
        text-align: left;
        .option {
            display: block;
            text-align: left;
            .smart-option,
            .smart-option-ui {
                display: block;
                text-align: left;
            }
            .smart-option {
                margin: 0;
                margin-top: -1px;
            }
        }
        label {
            &:first-child .smart-option {
                border-radius: 3px 3px 0 0;
            }
            &:last-child .smart-option {
                border-radius: 0 0 3px 3px;
            }
        }
    }
    .smart-option-ui .iconc-pos {
        -webkit-transition: margin 0.6s, opacity 0.6s;
        -moz-transition: margin 0.6s, opacity 0.6s;
        -ms-transition: margin 0.6s, opacity 0.6s;
        -o-transition: margin 0.6s, opacity 0.6s;
        transition: margin 0.6s, opacity 0.6s;
        display: inline-block;
        margin-right: -80px;
        position: absolute;
        right: -200px;
        opacity: 0;
    }
    .option>input:checked+.smart-option .iconc-pos {
        opacity: 1;
        right: 20px;
        margin-right: 0;
    }
    .modern-switch {
        position: relative;
        display: inline-block;
        vertical-align: top;
        margin-right: 10px;
        cursor: pointer;
        input {
            position: absolute;
            clip: rect(1px 1px 1px 1px);
            clip: rect(1px, 1px, 1px, 1px);
            overflow: hidden;
            height: inherit;
            width: inherit;
            opacity: 0;
            left: 0;
        }
        .switch-label {
            display: inline-block;
            position: relative;
            top: 2px;
        }
        >.switch-label {
            padding-right: 5px;
        }
        .switch-toggle {
            +.switch-label {
                padding-left: 5px;
            }
            width: 44px;
            height: 16px;
            background: #B5B5B5;
            position: relative;
            display: inline-block;
            background: rgba(0,
            0,
            0,
            0.26);
            -webkit-box-shadow: inset 0 1px 1px rgba(0,
            0,
            0,
            0.08),
            0 1px rgba(255,
            255,
            255,
            0.5);
            -moz-box-shadow: inset 0 1px 1px rgba(0,
            0,
            0,
            0.08),
            0 1px rgba(255,
            255,
            255,
            0.5);
            -o-box-shadow: inset 0 1px 1px rgba(0,
            0,
            0,
            0.08),
            0 1px rgba(255,
            255,
            255,
            0.5);
            box-shadow: inset 0 1px 1px rgba(0,
            0,
            0,
            0.08),
            0 1px rgba(255,
            255,
            255,
            0.5);
            -webkit-transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            -ms-transition: all 0.4s ease;
            -o-transition: all 0.4s ease;
            transition: all 0.4s ease;
            vertical-align: middle;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            -o-border-radius: 10px;
            border-radius: 10px;
            cursor: pointer;
            &::before {
                content: '';
                position: absolute;
                top: -6px;
                left: -4px;
                width: 26px;
                height: 26px;
                background: #fff;
                -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.18);
                -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.18);
                -o-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.18);
                box-shadow: 0 1px 4px rgba(0, 0, 0, 0.18);
                -webkit-transition: all 0.4s ease;
                -moz-transition: all 0.4s ease;
                -ms-transition: all 0.4s ease;
                -o-transition: all 0.4s ease;
                transition: all 0.4s ease;
                -webkit-border-radius: 26px;
                -moz-border-radius: 26px;
                -o-border-radius: 26px;
                border-radius: 26px;
            }
        }
        input {
            &:checked+.switch-toggle {
                background: #4EBD4A;
                &::before {
                    left: 20px;
                    background: #317D2F;
                }
            }
            &:disabled+.switch-toggle {
                background: #d5d5d5;
                pointer-events: none;
                &::before {
                    background: #bcbdbc;
                }
                +.switch-label {
                    color: #D5D5D5;
                }
            }
        }
    }
    .modern-ios {
        .switch-toggle {
            width: 40px;
            height: 24px;
            -webkit-border-radius: 50px;
            -moz-border-radius: 50px;
            border-radius: 50px;
            &::before {
                width: 22px;
                height: 22px;
                bottom: 1px;
                left: 1px;
                top: 1px;
            }
        }
        input {
            &:checked+.switch-toggle::before {
                left: 17px;
                background: #fff;
            }
            &:disabled+.switch-toggle::before {
                background: #f5f5f5;
            }
        }
    }
    .modern-switch {
        &:active .switch-toggle::before,
        .switch-toggle:active::before {
            -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
            -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
            -o-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(128, 128, 128, 0.1);
        }
        &:active input:checked+.switch-toggle::before,
        input:checked+.switch-toggle:active::before {
            -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(78, 189, 74, 0.2);
            -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(78, 189, 74, 0.2);
            -o-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(78, 189, 74, 0.2);
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.28), 0 0 0 20px rgba(78, 189, 74, 0.2);
        }
    }
    .morph-switch {
        &:active .switch-toggle:before,
        .switch-toggle:active::before,
        &:active input:checked+.switch-toggle::before,
        input:checked+.switch-toggle:active::before {
            -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.18);
            -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.18);
            -o-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.18);
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.18);
            -webkit-transform: scale(1.15, 0.85);
            -moz-transform: scale(1.15, 0.85);
            -ms-transform: scale(1.15, 0.85);
            -o-transform: scale(1.15, 0.85);
            transform: scale(1.15, 0.85);
        }
    }
    .button {
        border: 0;
        height: 42px;
        color: #243140;
        line-height: 1;
        font-size: 15px;
        cursor: pointer;
        padding: 0 18px;
        text-align: center;
        vertical-align: top;
        background: #DBDBDB;
        display: inline-block;
        -webkit-user-drag: none;
        text-shadow: 0 1px rgba(255, 255, 255, 0.2);
        &:hover {
            color: #243140;
            background: #E8E8E8;
        }
        &:active {
            color: #1d2938;
            background: #C4C4C4;
        }
    }
    a.button,
    span.button,
    label.button {
        line-height: 42px;
        text-decoration: none;
    }
    .button i {
        font-size: 14px;
    }
    .button-list .button {
        margin-bottom: 5px;
    }
    .btn-primary {
        background-color: #4ebd4a;
        &:hover,
        &:focus {
            background-color: #6bc868;
        }
        &:active {
            background-color: #3c9b39;
        }
        color: #fff;
        text-shadow: 0 1px rgba(0,
        0,
        0,
        0.08);
        &:hover,
        &:focus,
        &:active {
            color: #fff;
            text-shadow: 0 1px rgba(0, 0, 0, 0.08);
        }
    }
    .btn-rounded {
        -webkit-border-radius: 22px;
        -moz-border-radius: 22px;
        -o-border-radius: 22px;
        border-radius: 22px;
    }
    .button-left,
    .button-right {
        position: relative;
        z-index: 9;
    }
    .button-left:before,
    .button-right:before {
        content: '';
        z-index: -1;
        width: 32px;
        height: 32px;
        position: absolute;
        background-color: inherit;
        border-color: inherit;
        border: none;
        top: 5px;
    }
    .button-left {
        border-left-width: 0;
        padding: 0 18px 0 7px;
        -webkit-border-radius: 0 3px 3px 0;
        -moz-border-radius: 0 3px 3px 0;
        -o-border-radius: 0 3px 3px 0;
        border-radius: 0 3px 3px 0;
        margin-left: 20px;
        &:before {
            left: -15px;
            -webkit-border-radius: 2px 5px 0 5px;
            -moz-border-radius: 2px 5px 0 5px;
            -o-border-radius: 2px 5px 0 5px;
            border-radius: 2px 5px 0 5px;
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }
    }
    .button-right {
        padding: 0 7px 0 18px;
        border-right-width: 0;
        -webkit-border-radius: 3px 0 0 3px;
        -moz-border-radius: 3px 0 0 3px;
        -o-border-radius: 3px 0 0 3px;
        border-radius: 3px 0 0 3px;
        margin-right: 20px;
        &:before {
            right: -15px;
            -webkit-border-radius: 5px 2px 5px 0;
            -moz-border-radius: 5px 2px 5px 0;
            -o-border-radius: 5px 2px 5px 0;
            border-radius: 5px 2px 5px 0;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
    .btn-pointed {
        &.button-left,
        &.button-right {
            -webkit-border-radius: 22px;
            -moz-border-radius: 22px;
            -o-border-radius: 22px;
            border-radius: 22px;
        }
    }
    .btn-rounded {
        &.button-left {
            -webkit-border-radius: 0 22px 22px 0;
            -moz-border-radius: 0 22px 22px 0;
            -o-border-radius: 0 22px 22px 0;
            border-radius: 0 22px 22px 0;
        }
        &.button-right {
            -webkit-border-radius: 22px 0 0 22px;
            -moz-border-radius: 22px 0 0 22px;
            -o-border-radius: 22px 0 0 22px;
            border-radius: 22px 0 0 22px;
        }
    }
    .pushed {
        -webkit-box-shadow: inset 0 -0.3em 0 rgba(0, 0, 0, 0.2);
        -moz-box-shadow: inset 0 -0.3em 0 rgba(0, 0, 0, 0.2);
        -o-box-shadow: inset 0 -0.3em 0 rgba(0, 0, 0, 0.2);
        box-shadow: inset 0 -0.3em 0 rgba(0, 0, 0, 0.2);
        position: relative;
        &:active {
            -webkit-box-shadow: inset 0 -0.15em 0 rgba(0, 0, 0, 0.2);
            -moz-box-shadow: inset 0 -0.15em 0 rgba(0, 0, 0, 0.2);
            -o-box-shadow: inset 0 -0.15em 0 rgba(0, 0, 0, 0.2);
            box-shadow: inset 0 -0.15em 0 rgba(0, 0, 0, 0.2);
            top: 2px;
        }
        &.button-left:before {
            -webkit-box-shadow: inset 0.35em 0 0 rgba(0, 0, 0, 0.2);
            -moz-box-shadow: inset 0.35em 0 0 rgba(0, 0, 0, 0.2);
            -o-box-shadow: inset 0.35em 0 0 rgba(0, 0, 0, 0.2);
            box-shadow: inset 0.35em 0 0 rgba(0, 0, 0, 0.2);
        }
        &:active.button-left:before {
            -webkit-box-shadow: inset 0.2em 0 0 rgba(0, 0, 0, 0.2);
            -moz-box-shadow: inset 0.2em 0 0 rgba(0, 0, 0, 0.2);
            -o-box-shadow: inset 0.2em 0 0 rgba(0, 0, 0, 0.2);
            box-shadow: inset 0.2em 0 0 rgba(0, 0, 0, 0.2);
        }
        &.button-right:before {
            -webkit-box-shadow: inset -0.35em 0 0 rgba(0, 0, 0, 0.2);
            -moz-box-shadow: inset -0.35em 0 0 rgba(0, 0, 0, 0.2);
            -o-box-shadow: inset -0.35em 0 0 rgba(0, 0, 0, 0.2);
            box-shadow: inset -0.35em 0 0 rgba(0, 0, 0, 0.2);
        }
        &:active.button-right:before {
            -webkit-box-shadow: inset -0.2em 0 0 rgba(0, 0, 0, 0.2);
            -moz-box-shadow: inset -0.2em 0 0 rgba(0, 0, 0, 0.2);
            -o-box-shadow: inset -0.2em 0 0 rgba(0, 0, 0, 0.2);
            box-shadow: inset -0.2em 0 0 rgba(0, 0, 0, 0.2);
        }
    }
    .form-footer .button {
        margin-right: 10px;
        margin-bottom: 5px;
    }
    .align-right .button {
        margin-right: 0;
        margin-left: 10px;
    }
    .twitter {
        color: #fff;
        text-shadow: 0 1px rgba(0, 0, 0, 0.08);
        &:hover,
        &:focus {
            color: #fff;
            text-shadow: 0 1px rgba(0, 0, 0, 0.08);
        }
    }
    .facebook {
        color: #fff;
        text-shadow: 0 1px rgba(0, 0, 0, 0.08);
        &:hover,
        &:focus {
            color: #fff;
            text-shadow: 0 1px rgba(0, 0, 0, 0.08);
        }
    }
    .googleplus {
        color: #fff;
        text-shadow: 0 1px rgba(0, 0, 0, 0.08);
        &:hover,
        &:focus {
            color: #fff;
            text-shadow: 0 1px rgba(0, 0, 0, 0.08);
        }
    }
    .facebook {
        background-color: #3b5998;
    }
    .twitter {
        background-color: #00acee;
    }
    .googleplus {
        background-color: #dd4b39;
    }
    .facebook {
        &:hover,
        &:focus {
            background-color: #25385F;
        }
    }
    .twitter {
        &:hover,
        &:focus {
            background-color: #00749F;
        }
    }
    .googleplus {
        &:hover,
        &:focus {
            background-color: #8D2418;
        }
    }
    .span-left {
        padding-left: 52px;
        text-align: left;
    }
    .btn-social {
        position: relative;
        margin-bottom: 5px;
        i {
            font-size: 22px;
            position: relative;
            top: 2px;
        }
        span {
            -webkit-border-radius: 3px 0 0 3px;
            -moz-border-radius: 3px 0 0 3px;
            -o-border-radius: 3px 0 0 3px;
            border-radius: 3px 0 0 3px;
            display: inline-block;
            text-align: center;
            position: absolute;
            width: 42px;
            left: 0;
        }
    }
    .twitter span {
        background-color: #009AD5;
    }
    .facebook span {
        background-color: #31497D;
    }
    .googleplus span {
        background-color: #C03121;
    }
    .rating {
        position: relative;
        &.block {
            display: block;
            margin: 10px 0;
        }
        label {
            color: #A2A6A8;
            i {
                font-size: 17px;
                text-align: center;
                color: inherit;
            }
            span {
                font: 22px/22px Times, Serif;
            }
        }
    }
    .rating-star {
        margin-left: 4px;
    }
    .rating-input {
        position: absolute;
        left: -9999px;
        top: auto;
    }
    .rating:hover .rating-star:hover {
        color: #4ebd4a;
        ~.rating-star {
            color: #4ebd4a;
        }
    }
    .rating-input:checked~.rating-star {
        color: #4ebd4a;
    }
    .rating-star {
        width: 18px;
        float: right;
        display: block;
        cursor: pointer;
        color: #A2A6A8;
    }
    .rating {
        &:hover .rating-star {
            width: 18px;
            float: right;
            display: block;
            cursor: pointer;
            color: #A2A6A8;
        }
        &:before {
            display: table;
            content: "";
            line-height: 0;
        }
        &:after {
            display: table;
            content: "";
            line-height: 0;
            clear: both;
        }
        .lbl-text {
            font-size: 14px;
            display: inline-block;
            float: left;
        }
    }
    .rating-wrapper {
        position: relative;
        display: inline-block;
        margin-top: 0;
        float: right;
    }
    .rating label {
        span {
            font-family: Arial, Helvetica, sans-serif;
            text-shadow: 0 1px rgba(255, 255, 255, 0);
            -webkit-transition: margin 0.6s, opacity 0.6s;
            -moz-transition: margin 0.6s, opacity 0.6s;
            -ms-transition: margin 0.6s, opacity 0.6s;
            -o-transition: margin 0.6s, opacity 0.6s;
            transition: margin 0.6s, opacity 0.6s;
            -webkit-border-radius: 3px;
            -moz-border-radius: 3px;
            border-radius: 3px;
            white-space: nowrap;
            position: absolute;
            padding: 5px 10px;
            font-weight: bold;
            background: #000;
            font-size: 11px;
            margin-left: -5px;
            margin-bottom: 15px;
            display: block;
            left: -9999px;
            color: #fff;
            opacity: 0;
            z-index: 10;
        }
        &:hover span {
            bottom: 100%;
            opacity: 1;
            left: auto;
            margin-bottom: 5px;
        }
        span:before {
            content: '';
            position: absolute;
            border-top: 4px solid #000;
            border-right: 4px solid transparent;
            border-left: 4px solid transparent;
            left: 8px;
            top: 100%;
        }
    }
    .smart-widget,
    .append-picker-icon,
    .prepend-picker-icon {
        position: relative;
        display: block;
    }
    .smart-widget .field input,
    .append-picker-icon input,
    .prepend-picker-icon input {
        width: 100%;
    }
    .append-picker-icon button,
    .prepend-picker-icon button,
    .smart-widget .button {
        border: 1px solid #CFCFCF;
        background: #F5F5F5;
        position: absolute;
        cursor: pointer;
        color: #626262;
        height: 42px;
        top: 0;
    }
    .sm-right .button,
    .append-picker-icon button {
        border-left: 0;
    }
    .sm-left .button,
    .prepend-picker-icon button {
        border-right: 0;
    }
    .sm-left .button,
    .prepend-picker-icon button {
        left: 0;
    }
    .sm-right .button,
    .append-picker-icon button {
        right: 0;
    }
    .sml-50,
    .prepend-picker-icon {
        padding-left: 50px;
    }
    .sml-50 .button,
    .prepend-picker-icon button {
        width: 50px;
    }
    .sml-80 {
        padding-left: 80px;
        .button {
            width: 80px;
        }
    }
    .sml-120 {
        padding-left: 120px;
        .button {
            width: 120px;
        }
    }
    .smr-50,
    .append-picker-icon {
        padding-right: 50px;
    }
    .smr-50 .button,
    .append-picker-icon button {
        width: 50px;
    }
    .smr-80 {
        padding-right: 80px;
        .button {
            width: 80px;
        }
    }
    .smr-120 {
        padding-right: 120px;
        .button {
            width: 120px;
        }
    }
    .append-icon {
        display: inline-block;
        vertical-align: top;
        position: relative;
        width: 100%;
    }
    .prepend-icon {
        display: inline-block;
        vertical-align: top;
        position: relative;
        width: 100%;
        .custom-combobox-input {
            padding-left: 42px;
        }
    }
    .append-icon .field-icon,
    .prepend-icon .field-icon {
        top: 0;
        width: 42px;
        height: 42px;
        color: inherit;
        line-height: 42px;
        position: absolute;
        text-align: center;
        -webkit-transition: all 0.5s ease-out;
        -moz-transition: all 0.5s ease-out;
        -ms-transition: all 0.5s ease-out;
        -o-transition: all 0.5s ease-out;
        transition: all 0.5s ease-out;
        pointer-events: none;
        z-index: 99;
    }
    .append-icon .field-icon i {
        position: relative;
        font-size: 14px;
    }
    .prepend-icon .field-icon {
        i {
            position: relative;
            font-size: 14px;
        }
        left: 0;
    }
    .append-icon .field-icon {
        right: 0;
    }
    .prepend-icon> {
        input,
        textarea,
        select {
            padding-left: 36px;
        }
    }
    .append-icon> {
        input {
            padding-right: 36px;
            padding-left: 10px;
        }
        textarea {
            padding-right: 36px;
            padding-left: 10px;
            padding-right: 36px;
        }
    }
    .tooltip {
        position: absolute;
        z-index: -1;
        opacity: 0;
        color: #fff;
        width: 184px;
        left: -9999px;
        top: auto;
        font-size: 11px;
        font-weight: normal;
        background: #333333;
        -webkit-transition: margin 0.6s, opacity 0.6s;
        -moz-transition: margin 0.6s, opacity 0.6s;
        -ms-transition: margin 0.6s, opacity 0.6s;
        -o-transition: margin 0.6s, opacity 0.6s;
        transition: margin 0.6s, opacity 0.6s;
        >em {
            padding: 12px;
            font-style: normal;
            display: block;
            position: static;
        }
        &:after {
            content: '';
            position: absolute;
        }
    }
    .option:hover .tooltip,
    .gui-input:focus+.tooltip,
    .gui-textarea:focus+.tooltip,
    .select>select:focus~.tooltip {
        opacity: 1;
        z-index: 999;
    }
    .tip-left {
        top: 1px;
        margin-right: -20px;
        &:after {
            top: 12px;
            left: 100%;
            border-left: 8px solid #333333;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
        }
    }
    .gui-input:focus+.tip-left,
    .gui-textarea:focus+.tip-left,
    .select>select:focus~.tip-left {
        margin-right: 5px;
        right: 100%;
        left: auto;
    }
    .tip-right {
        top: 1px;
        margin-left: -20px;
        &:after {
            top: 12px;
            right: 100%;
            border-right: 8px solid #333333;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
        }
    }
    .gui-input:focus+.tip-right,
    .gui-textarea:focus+.tip-right,
    .select>select:focus~.tip-right {
        left: 100%;
        margin-left: 5px;
    }
    .tip-right-top {
        bottom: 100%;
        margin-bottom: -20px;
        &:after {
            top: 100%;
            right: 12px;
            border-top: 8px solid #333333;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }
    }
    .gui-input:focus+.tip-right-top,
    .gui-textarea:focus+.tip-right-top,
    .select>select:focus~.tip-right-top {
        right: 0;
        left: auto;
        margin-bottom: 10px;
    }
    .option .tooltip,
    .tip-left-top {
        bottom: 100%;
        margin-bottom: -20px;
    }
    .option .tooltip:after,
    .tip-left-top:after {
        top: 100%;
        left: 12px;
        border-top: 8px solid #333333;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
    }
    .option:hover .tooltip,
    .gui-input:focus+.tip-left-top,
    .gui-textarea:focus+.tip-left-top,
    .select>select:focus~.tip-left-top {
        left: 0;
        right: auto;
        margin-bottom: 10px;
    }
    .option:hover .tooltip {
        left: -10px;
    }
    .tip-right-bottom {
        top: 100%;
        margin-top: -20px;
        &:after {
            right: 12px;
            bottom: 100%;
            border-bottom: 8px solid #333333;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }
    }
    .gui-input:focus+.tip-right-bottom,
    .gui-textarea:focus+.tip-right-bottom {
        margin-top: 10px;
        left: auto;
        right: 0;
    }
    .tip-left-bottom {
        top: 100%;
        margin-top: -20px;
        &:after {
            left: 12px;
            bottom: 100%;
            border-bottom: 8px solid #333333;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }
    }
    .gui-input:focus+.tip-left-bottom,
    .gui-textarea:focus+.tip-left-bottom {
        margin-top: 10px;
        right: auto;
        left: 0;
    }
    .smart-list {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            margin-bottom: 20px;
        }
    }
    .form-msg {
        display: none;
    }
    .notification {
        color: #444;
        padding: 15px;
        position: relative;
        p {
            margin: 0;
            padding: 0 15px;
            padding-left: 5px;
            line-height: normal;
        }
        .close-btn {
            margin-top: -7px;
            padding: inherit;
            position: absolute;
            text-decoration: none;
            font: bold 20px/20px Arial, sans-serif;
            opacity: 0.65;
            color: inherit;
            display: block;
            right: 1px;
            top: 14%;
            &:hover {
                opacity: 1;
            }
        }
    }
    .alert-info {
        color: #163161;
        background-color: #cfe6fc;
    }
    .alert-success {
        color: #336633;
        background-color: #d2f7ad;
    }
    .alert-warning {
        color: #CC6600;
        background-color: #fae7a2;
    }
    .alert-error {
        color: #990000;
        background-color: #FBDBCF;
    }
    .notification ul {
        margin-left: 1.3em;
        list-style-position: inside;
        margin-top: 10px;
    }
    .state-error {
        .gui-input,
        .gui-textarea,
        &.select>select,
        &.select-multiple>select {
            background: #FEE9EA;
            border-color: #DE888A;
        }
        input {
            &:hover+ {
                .checkbox,
                .radio {
                    background: #FEE9EA;
                    border-color: #DE888A;
                }
            }
            &:focus+ {
                .checkbox,
                .radio {
                    background: #FEE9EA;
                    border-color: #DE888A;
                }
            }
        }
        .checkbox,
        .radio {
            background: #FEE9EA;
            border-color: #DE888A;
        }
        .gui-input:focus,
        .gui-textarea:focus,
        &.select>select:focus,
        &.select-multiple>select:focus {
            -webkit-box-shadow: 0px 0px 3px #DE888A inset;
            -moz-box-shadow: 0px 0px 3px #DE888A inset;
            -o-box-shadow: 0px 0px 3px #DE888A inset;
            box-shadow: 0px 0px 3px #DE888A inset;
        }
        .gui-input~.field-icon i,
        .gui-textarea~.field-icon i {
            color: #DE888A;
        }
        &.select {
            .arrow,
            >select:focus+.arrow {
                color: #DE888A;
            }
        }
        .gui-input~.input-hint,
        &.file .gui-file:hover+.gui-input,
        .gui-textarea~.input-hint {
            border-color: #DE888A;
        }
        +em {
            display: block !important;
            margin-top: 6px;
            padding: 0 3px;
            font-family: Arial, Helvetica, sans-serif;
            font-style: normal;
            line-height: normal;
            font-size: 0.85em;
            color: #DE888A;
        }
    }
    .state-success {
        .gui-input,
        .gui-textarea,
        &.select>select,
        &.select-multiple>select {
            background: #F0FEE9;
            border-color: #A5D491;
        }
        input {
            &:hover+ {
                .checkbox,
                .radio {
                    background: #F0FEE9;
                    border-color: #A5D491;
                }
            }
            &:focus+ {
                .checkbox,
                .radio {
                    background: #F0FEE9;
                    border-color: #A5D491;
                }
            }
        }
        .checkbox,
        .radio {
            background: #F0FEE9;
            border-color: #A5D491;
        }
        .gui-input:focus,
        .gui-textarea:focus,
        &.select>select:focus,
        &.select-multiple>select:focus {
            -webkit-box-shadow: 0px 0px 3px #A5D491 inset;
            -moz-box-shadow: 0px 0px 3px #A5D491 inset;
            -o-box-shadow: 0px 0px 3px #A5D491 inset;
            box-shadow: 0px 0px 3px #A5D491 inset;
        }
        .gui-input~.field-icon i,
        .gui-textarea~.field-icon i {
            color: #A5D491;
        }
        &.select {
            .arrow,
            >select:focus+.arrow {
                color: #A5D491;
            }
        }
        .gui-input~.input-hint,
        &.file .gui-file:hover+.gui-input,
        .gui-textarea~.input-hint {
            border-color: #A5D491;
        }
    }
    .button[disabled],
    .state-disabled .button {
        cursor: default;
        opacity: 0.5;
    }
    input[disabled]+ {
        .radio,
        .checkbox {
            cursor: default;
            opacity: 0.5;
        }
    }
    .switch>input[disabled]+label {
        cursor: default;
        opacity: 0.5;
    }
    .gui-input[disabled],
    .gui-textarea[disabled],
    .select>select[disabled],
    .select-multiple select[disabled],
    .gui-input[disabled]~.input-hint {
        background-color: #f4f6f6;
        border-color: #d5dbdb !important;
        cursor: default;
        color: #d5dbdb;
        opacity: 0.7;
    }
    .file .gui-file[disabled] {
        +.gui-input,
        &:hover+.gui-input {
            background-color: #f4f6f6;
            border-color: #d5dbdb !important;
            cursor: default;
            color: #d5dbdb;
            opacity: 0.7;
        }
    }
    .gui-textarea[disabled]~.input-hint {
        background-color: #f4f6f6;
        border-color: #d5dbdb !important;
        cursor: default;
        color: #d5dbdb;
        opacity: 0.7;
    }
    input[disabled]~.field-icon i,
    textarea[disabled]~.field-icon i,
    .select>select[disabled]+.arrow {
        opacity: 0.4;
    }
}


/* @crossbrowser placeholder styling :: modern browsers only IE10+
------------------------------------------------------------------------ */


/* @element general styling :: fonts :: adjust accordingly
------------------------------------------------------------- */


/* @remove browser specific styling
----------------------------------------------- */


/* @labels font-size styling :: adjust to fit your needs 
--------------------------------------------------------- */


/* @prevent user selection for usability purposes
----------------------------------------------------- */


/* @universal rules for all elements 
---------------------------------------------------- */


/* @control border-size :: color etc for these elements 
----------------------------------------------------------- */


/* @styling inputs and textareas 
------------------------------------------- */


/* @hint below textareas 
---------------------------------------- */


/* @form selects :: dropdowns 
-------------------------------------------------- */


/* @file inputs :: file uploaders 
-------------------------------------------------------- */


/* @form element :hover state 
-------------------------------------------- */


/* @form element :focus state 
-------------------------------------------------------- */


/* @radio + checkbox option elements 
----------------------------------------------------- */


/* @radio + checkbox :hover state 
-------------------------------------------------- */


/* @radio + checkbox :focus state 
--------------------------------------------------- */


/* @toggle switch elements 
-------------------------------------------------- */


/* @toggle switch focus state 
-------------------------------------------------------------- */


/* @toggle switch normal state 
--------------------------------------------------------------- */


/* @toggle switch normal state focus 
--------------------------------------------------------------------------------- */


/* Smart-options 
-------------------------------------------*/


/* modern switches  
------------------------------------------------------ */


/* switch ripple 
------------------------------------------------------- */


/* switch morph animate 
--------------------------------------------------- */


/* @buttons 
----------------------------------------------------- */


/* @buttons :hover, :active states 
---------------------------------------------------------------- */


/* @primary button theme
-------------------------------------------- */


/* @rounded buttons 
-------------------------------------------- */


/* @left + right buttons :: look like IOS
-------------------------------------------- */


/* @left right button pointed button shapes 
------------------------------------------------ */


/* @push buttons
------------------------------------------------ */


/* @adjust buttons in form footer
------------------------------------------------ */


/* @social buttons :: facebook :: twitter :: google +
---------------------------------------------------- */


/* @rating and review star widget :: with hover back afetr selecting
------------------------------------------------------------------------ */


/* @smart widget
   @this widget helps us to position an element eg button or label or span
   @the positions can either be left or right while the input stays 100%
   @you ca use this to rapidly create search widgets, newsletter subscribe etc 
---------------------------------------------------------------------------------*/


/* @smart widget buttons - to left 
------------------------------------------------- */


/* @smart widget buttons - to right 
------------------------------------------------- */


/* @icon append (right) :: prepend (left)
------------------------------------------------- */


/* @tooltips on inputs + textareas 
------------------------------------------------- */


/* @tooltip left
------------------------------------------------- */


/* @tooltip right
------------------------------------------------- */


/* @tooltip right-top
------------------------------------------------- */


/* @tooltip left-top
------------------------------------------------- */


/* @tooltip right-bottom
------------------------------------------------- */


/* @tooltip left-bottom
------------------------------------------------- */


/* @lists
-------------------------------------------------------------- */


/* @notification messages | info | error | warning | success
-------------------------------------------------------------- */


/* @validaion - error state
------------------------------------- */


/* @validaion - success state 
-------------------------------------------------- */


/* @disabled state 
----------------------------------------------- */


/* @jquery ui helper classes 
----------------------------------------------- */

.ui-helper-hidden {
    display: none;
}

.ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.ui-helper-reset {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    line-height: 1.3;
    text-decoration: none;
    font-size: 100%;
    list-style: none;
}

.ui-helper-clearfix {
    &:before {
        content: "";
        display: table;
        border-collapse: collapse;
    }
    &:after {
        content: "";
        display: table;
        border-collapse: collapse;
        clear: both;
    }
    min-height: 0;
}

.ui-helper-zfix {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    opacity: 0;
    filter: Alpha(Opacity=0);
}

.ui-front {
    z-index: 100;
}


/* @datepicker - requires jquery ui
----------------------------------------------- */

.ui-datepicker {
    width: 18em;
    margin-top: 8px;
    display: none;
    background: #fff;
    position: relative;
    font: 14px/1.55 "Roboto", Arial, Helvetica, sans-serif;
    -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #CFCFCF;
    z-index: 9999 !important;
    text-align: center;
    color: #666;
    a {
        color: #404040;
        text-align: center;
    }
    .ui-state-disabled span {
        color: #DBDBDB;
    }
    .ui-datepicker-header {
        position: relative;
        background: #F5F5F5;
        border-bottom: 1px solid #CFCFCF;
        line-height: 27px;
        font-size: 15px;
        padding: 10px;
    }
    .ui-datepicker-prev,
    .ui-datepicker-next {
        width: 34px;
        height: 34px;
        display: block;
        font-size: 14px;
        position: absolute;
        text-decoration: none;
        cursor: pointer;
        color: #4ebd4a;
        top: 20.5%;
    }
    .ui-datepicker-prev {
        left: 2px;
    }
    .ui-datepicker-next {
        right: 2px;
    }
    .ui-datepicker-title {
        margin: 0 2.3em;
        line-height: 1.8em;
        text-align: center;
        color: #4ebd4a;
        select {
            font-size: 1em;
            margin: 1px 0;
        }
    }
    select {
        &.ui-datepicker-month-year {
            width: 100%;
        }
        &.ui-datepicker-month,
        &.ui-datepicker-year {
            width: 49%;
        }
    }
    table {
        width: 100%;
        font-size: .9em;
        margin: 0 0 .4em;
        border-collapse: collapse;
    }
    th {
        padding: .5em .3em;
        text-align: center;
        font-weight: 700;
        border: 0;
    }
    td {
        border: 0;
        padding: 2px 5px;
        span,
        a {
            padding: .25em;
            display: block;
            text-align: center;
            text-decoration: none;
        }
        span:hover,
        a:hover {
            background: #F5F5F5;
        }
    }
    .ui-state-disabled span:hover {
        background: none;
    }
}


/* @changes to the datepicker and month picker widget in v.4  
------------------------------------------------------------- */

.ui-datepicker-today a {
    background: #FFFA90 !important;
    font-weight: 700;
    color: #444;
    &:hover {
        background: #FFFA90 !important;
        font-weight: 700;
        color: #444;
    }
}

.ui-datepicker .ui-state-highlight {
    background: #FFFA90 !important;
    font-weight: 700;
    color: #444;
}

.ui-monthpicker .ui-datepicker-today a {
    font-weight: 700;
    background: #4ebd4a !important;
    color: #fff;
    &:hover {
        font-weight: 700;
        background: #4ebd4a !important;
        color: #fff;
    }
}

.ui-datepicker .ui-datepicker-current-day a {
    font-weight: 700;
    background: #4ebd4a !important;
    color: #fff;
}


/* @multiple calendars || not responsive use carefully 
--------------------------------------------------------------- */

.cal-widget .ui-datepicker {
    width: 100%;
    margin-top: 0;
    &:before {
        display: none;
    }
}

.ui-datepicker.ui-datepicker-multi {
    width: auto;
}

.ui-datepicker-multi .ui-datepicker-group {
    float: left;
    table {
        width: 95%;
        margin: 0 auto .4em;
    }
}

.ui-datepicker-multi-2 .ui-datepicker-group {
    width: 50%;
}

.ui-datepicker-multi-3 .ui-datepicker-group {
    width: 33.333%;
}

.ui-datepicker-multi-4 .ui-datepicker-group {
    width: 25%;
}

.ui-datepicker-multi {
    .ui-datepicker-group-last .ui-datepicker-header,
    .ui-datepicker-group-middle .ui-datepicker-header {
        border-left-width: 0;
    }
    .ui-datepicker-buttonpane {
        clear: left;
    }
}

.ui-datepicker-row-break {
    clear: both;
    width: 100%;
    font-size: 0;
}


/* @ ui buttons
---------------------------------------------------------------- */

.ui-datepicker-buttonpane {
    border-top: 1px solid #CFCFCF;
    padding: 10px;
    button {
        padding: 8px 12px;
        margin-right: .2em;
        position: relative;
        line-height: normal;
        display: inline-block;
        -webkit-user-drag: none;
        text-shadow: 0 1px rgba(255, 255, 255, 0.2);
        vertical-align: middle;
        background: #DBDBDB;
        text-align: center;
        overflow: visible;
        cursor: pointer;
        color: #243140;
        border: 0;
        &:hover {
            color: #243140;
            background: #E8E8E8;
        }
        &:active {
            color: #1d2938;
            background: #C4C4C4;
        }
    }
}


/* @ ui buttons :hover, :active states 
---------------------------------------------------------------- */

.ui-monthpicker .ui-datepicker-header {
    margin-bottom: 3px;
}


/* @ui slider - requires jquery ui
------------------------------------------------------*/

.smart-forms {
    .slider-wrapper,
    .sliderv-wrapper {
        background: #E5E5E5;
        position: relative;
    }
    .ui-slider {
        position: relative;
        text-align: left;
        .ui-slider-handle {
            position: absolute;
            z-index: 2;
            width: 21px;
            height: 21px;
            cursor: default;
            background: #fff;
            border: 3px solid #4ebd4a;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            -o-border-radius: 20px;
            border-radius: 20px;
            -ms-touch-action: none;
            touch-action: none;
            margin-top: -4px;
            outline: none;
            &:before {
                content: '';
                width: 8px;
                height: 8px;
                position: absolute;
                background-color: #4ebd4a;
                -webkit-border-radius: 10px;
                -moz-border-radius: 10px;
                -o-border-radius: 10px;
                border-radius: 10px;
                z-index: 2;
                left: 4px;
                top: 4px;
            }
        }
        .ui-slider-range {
            position: absolute;
            z-index: 1;
            font-size: .7em;
            display: block;
            border: 0;
            background-position: 0 0;
            background-color: #4ebd4a;
        }
        &.ui-state-disabled {
            .ui-slider-handle,
            .ui-slider-range {
                filter: inherit;
            }
        }
    }
    .ui-slider-horizontal {
        height: .5em;
        .ui-slider-handle {
            top: -.3em;
            margin-left: -.6em;
        }
        .ui-slider-range {
            top: 0;
            height: 100%;
        }
        .ui-slider-range-min {
            left: 0;
        }
        .ui-slider-range-max {
            right: 0;
        }
    }
    .ui-slider-vertical,
    .sliderv-wrapper {
        width: .5em;
        height: 100px;
    }
    .ui-slider-vertical {
        .ui-slider-handle {
            left: -.45em;
            margin-left: 0;
            margin-bottom: -.6em;
        }
        .ui-slider-range {
            left: 0;
            width: 100%;
        }
        .ui-slider-range-min {
            bottom: 0;
        }
        .ui-slider-range-max {
            top: 0;
        }
    }
    .slider-input {
        color: #f6931f !important;
        border: 0;
        background: none;
    }
    .slider-group .sliderv-wrapper {
        height: 150px;
        float: left;
        margin: 15px 15px;
    }
    .ui-slider .ui-state-active {
        cursor: -webkit-grabbing;
        cursor: -moz-grabbing;
        cursor: grabbing;
    }
    .slider-tip {
        display: block;
        position: absolute;
        text-align: center;
        font: 10pt Tahoma, Arial, sans-serif;
        background: #333333;
        padding: 10px;
        color: #fff;
    }
    .slider-wrapper .slider-tip {
        top: -50px;
        left: -15px;
        &:after {
            content: '';
            position: absolute;
            top: 98%;
            left: 35%;
            border-top: 8px solid #333333;
            border-right: 8px solid transparent;
            border-left: 8px solid transparent;
        }
    }
    .sliderv-wrapper .slider-tip {
        left: 30px;
        top: -12px;
        &:after {
            content: '';
            position: absolute;
            top: 30%;
            right: 98%;
            border-right: 8px solid #333333;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
        }
    }
    .yellow-slider .ui-slider {
        .ui-slider-handle {
            border-color: #faa226;
            &:before {
                background-color: #faa226;
            }
        }
        .ui-slider-range {
            background-color: #faa226;
        }
    }
    .red-slider .ui-slider {
        .ui-slider-handle {
            border-color: #ee4f3d;
            &:before {
                background-color: #ee4f3d;
            }
        }
        .ui-slider-range {
            background-color: #ee4f3d;
        }
    }
    .purple-slider .ui-slider {
        .ui-slider-handle {
            border-color: #9464e2;
            &:before {
                background-color: #9464e2;
            }
        }
        .ui-slider-range {
            background-color: #9464e2;
        }
    }
    .blue-slider .ui-slider {
        .ui-slider-handle {
            border-color: #00acee;
            &:before {
                background-color: #00acee;
            }
        }
        .ui-slider-range {
            background-color: #00acee;
        }
    }
    .black-slider .ui-slider {
        .ui-slider-handle {
            border-color: #505558;
            &:before {
                background-color: #505558;
            }
        }
        .ui-slider-range {
            background-color: #505558;
        }
    }
    .green-slider .ui-slider {
        .ui-slider-handle {
            border-color: #0E993C;
            &:before {
                background-color: #0E993C;
            }
        }
        .ui-slider-range {
            background-color: #0E993C;
        }
    }
}


/* @changes to the ui slider widget in v.4  
------------------------------------------------------------- */


/* @ui slider tooltip
------------------------------------------------------*/


/* @ui slider themes
------------------------------------------------------*/


/* @ui timepicker - requires jquery ui
------------------------------------------------------*/

.ui-timepicker-div {
    .ui-widget-header {
        position: relative;
        background: #F5F5F5;
        line-height: 27px;
        font-size: 15px;
        padding: 10px;
    }
    dl {
        text-align: left;
        border: 1px solid #CFCFCF;
        border-width: 1px 0 0 0;
        padding: 15px 10px;
        padding-bottom: 15px;
        margin: 0;
        dt {
            float: left;
            clear: left;
            padding: 0 0 0 5px;
        }
        dd {
            margin: 0 10px 15px 40%;
        }
    }
    td {
        font-size: 90%;
    }
}

.ui-tpicker-grid-label {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
}

.ui-timepicker-div .ui_tpicker_unit_hide {
    display: none;
}

.ui-timepicker-rtl {
    direction: rtl;
    dl {
        text-align: right;
        padding: 0 5px 0 0;
        dt {
            float: right;
            clear: right;
        }
        dd {
            margin: 0 40% 10px 10px;
            background: #E5E5E5;
            position: relative;
            top: 6px;
        }
    }
}

.ui-timepicker-div dl {
    .ui_tpicker_hour,
    .ui_tpicker_minute,
    .ui_tpicker_second,
    .ui_tpicker_millisec {
        background: #E5E5E5;
        position: relative;
        top: 5px;
    }
}

.ui_tpicker_time_input {
    background: none;
    border: 0;
    display: inline-block;
}


/* Shortened version style */

.ui-timepicker-div.ui-timepicker-oneLine {
    padding-right: 2px;
    .ui_tpicker_time,
    dt {
        display: none;
    }
    .ui_tpicker_time_label {
        display: block;
        padding-top: 2px;
    }
    dl {
        text-align: right;
        dd {
            display: inline-block;
            margin: 0;
            >div {
                display: inline-block;
                margin: 0;
            }
            &.ui_tpicker_minute:before,
            &.ui_tpicker_second:before {
                content: ':';
                display: inline-block;
            }
            &.ui_tpicker_millisec:before,
            &.ui_tpicker_microsec:before {
                content: '.';
                display: inline-block;
            }
        }
    }
    .ui_tpicker_unit_hide {
        display: none;
        &:before {
            display: none;
        }
    }
}


/* ui-autocomplete widget
----------------------------------------------------- */

.ui-autocomplete {
    position: absolute;
    top: 0;
    left: 0;
    cursor: default;
    z-index: 10000000;
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
    outline: none;
    font-family: "Roboto", Arial, Helvetica, sans-serif;
    font-size: 14px;
    border: 1px solid #bbb;
    border-top: 0;
    background: #fff;
    max-height: 100px;
    overflow-x: hidden;
    overflow-y: auto;
    .ui-menu {
        position: absolute;
    }
    .ui-menu-item {
        position: relative;
        margin: 0;
        padding: 8px 10px;
        cursor: pointer;
        min-height: 0;
        list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
        background: #F2F4FA;
        border-bottom: 1px solid #DCE1EE;
        -webkit-box-shadow: inset 0 1px #fff, inset 0 0 0 1px rgba(255, 255, 255, 0.5);
        -moz-box-shadow: inset 0 1px #fff, inset 0 0 0 1px rgba(255, 255, 255, 0.5);
        -o-box-shadow: inset 0 1px #fff, inset 0 0 0 1px rgba(255, 255, 255, 0.5);
        box-shadow: inset 0 1px #fff, inset 0 0 0 1px rgba(255, 255, 255, 0.5);
    }
    .ui-menu-divider {
        margin: 5px 0;
        height: 0;
        font-size: 0;
        line-height: 0;
        border-width: 1px 0 0 0;
        border-color: #ddd;
    }
    .ui-state-focus,
    .ui-state-active {}
    .ui-menu-item:hover {
        background: #DCE1EE;
    }
}


/* @jquery ui combobox widget
---------------------------------------- */

.custom-combobox {
    position: relative;
    padding-right: 45px;
    display: block;
}

.custom-combobox-toggle {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin-left: -1px;
    padding: 0;
    z-index: 10;
    width: 45px;
    line-height: 40px;
    height: 100%;
    text-align: center;
    background: #F5F5F5;
    border: 1px solid #CFCFCF;
    border-left: 0;
    cursor: pointer;
    color: #aaa;
}

.custom-combobox-input {
    margin: 0;
    padding: 5px 10px;
}


/* @progress bars 
------------------------------------------------------*/

.smart-forms {
    .progress-section {
        display: none;
    }
    .progress-bar {
        position: relative;
        background: #E5E5E5;
        .percent {
            position: absolute;
            display: inline-block;
            top: -3px;
            right: -24px;
            font-size: 9px;
            color: #93A2AA;
        }
        >.bar {
            width: 60%;
            height: 7px;
            display: block;
            background-size: 16px 16px;
            background-color: #bdc3c7;
        }
    }
    .bar-primary>.bar {
        background-color: #4ebd4a;
    }
    .bar-blue>.bar {
        background-color: #00acee;
    }
    .bar-black>.bar {
        background-color: #505558;
    }
    .bar-green>.bar {
        background-color: #0E993C;
    }
    .bar-purple>.bar {
        background-color: #9464e2;
    }
    .bar-red>.bar {
        background-color: #ee4f3d;
    }
    .bar-yellow>.bar {
        background-color: #faa226;
    }
    .ui-slider .ui-slider-range {
        background-size: 16px 16px;
        background-image: -webkit-linear-gradient(top left, transparent, transparent 25%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.3) 50%, transparent 50%, transparent 75%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0.3));
        background-image: -moz-linear-gradient(top left, transparent, transparent 25%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.3) 50%, transparent 50%, transparent 75%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0.3));
        background-image: -o-linear-gradient(top left, transparent, transparent 25%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.3) 50%, transparent 50%, transparent 75%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0.3));
        background-image: linear-gradient(to bottom right, transparent, transparent 25%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.3) 50%, transparent 50%, transparent 75%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0.3));
    }
    .progress>button[type="submit"] {
        background-size: 16px 16px;
        background-image: -webkit-linear-gradient(top left, transparent, transparent 25%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.3) 50%, transparent 50%, transparent 75%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0.3));
        background-image: -moz-linear-gradient(top left, transparent, transparent 25%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.3) 50%, transparent 50%, transparent 75%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0.3));
        background-image: -o-linear-gradient(top left, transparent, transparent 25%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.3) 50%, transparent 50%, transparent 75%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0.3));
        background-image: linear-gradient(to bottom right, transparent, transparent 25%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.3) 50%, transparent 50%, transparent 75%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0.3));
        &:hover {
            background-size: 16px 16px;
            background-image: -webkit-linear-gradient(top left, transparent, transparent 25%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.3) 50%, transparent 50%, transparent 75%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0.3));
            background-image: -moz-linear-gradient(top left, transparent, transparent 25%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.3) 50%, transparent 50%, transparent 75%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0.3));
            background-image: -o-linear-gradient(top left, transparent, transparent 25%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.3) 50%, transparent 50%, transparent 75%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0.3));
            background-image: linear-gradient(to bottom right, transparent, transparent 25%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.3) 50%, transparent 50%, transparent 75%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0.3));
        }
    }
    .progress-bar>.bar {
        background-size: 16px 16px;
        background-image: -webkit-linear-gradient(top left, transparent, transparent 25%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.3) 50%, transparent 50%, transparent 75%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0.3));
        background-image: -moz-linear-gradient(top left, transparent, transparent 25%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.3) 50%, transparent 50%, transparent 75%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0.3));
        background-image: -o-linear-gradient(top left, transparent, transparent 25%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.3) 50%, transparent 50%, transparent 75%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0.3));
        background-image: linear-gradient(to bottom right, transparent, transparent 25%, rgba(255, 255, 255, 0.3) 25%, rgba(255, 255, 255, 0.3) 50%, transparent 50%, transparent 75%, rgba(255, 255, 255, 0.3) 75%, rgba(255, 255, 255, 0.3));
    }
    .progress>button[type="submit"] {
        -webkit-animation: sfprogress .6s linear infinite;
        -moz-animation: sfprogress .6s linear infinite;
        -o-animation: sfprogress .6s linear infinite;
        animation: sfprogress .6s linear infinite;
        &:hover {
            -webkit-animation: sfprogress .6s linear infinite;
            -moz-animation: sfprogress .6s linear infinite;
            -o-animation: sfprogress .6s linear infinite;
            animation: sfprogress .6s linear infinite;
        }
    }
    .progress-animated>.bar {
        -webkit-animation: sfprogress .6s linear infinite;
        -moz-animation: sfprogress .6s linear infinite;
        -o-animation: sfprogress .6s linear infinite;
        animation: sfprogress .6s linear infinite;
    }
    .progress>button[type="submit"]:hover {
        cursor: wait;
    }
}


/* @progress bar themes
----------------------------------------------------------- */


/* @progress bar strips + animation IE10+ 
----------------------------------------------------------- */

@-webkit-keyframes sfprogress {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -16px 0;
    }
}

@-moz-keyframes sfprogress {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -16px 0;
    }
}

@-o-keyframes sfprogress {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -16px 0;
    }
}

@keyframes sfprogress {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -16px 0;
    }
}


/* @cloned widgets 
--------------------------------------------------------------- */

.clone-widget {
    padding-right: 100px;
    position: relative;
    .button {
        position: absolute;
        right: 0;
        top: 0;
    }
    .clone {
        right: 50px;
    }
}


/* @google map :: block elements 
----------------------------------------------------------------------- */

.smart-forms {
    .map-container {
        padding: 10px;
        margin-bottom: 10px;
        border: 1px solid #CFCFCF;
    }
    .map-area,
    #map_canvas {
        width: 100%;
        height: 400px;
        overflow: hidden;
    }
    .block {
        display: block;
    }
    .custom-block-list {
        list-style-type: none;
        display: block;
        padding: 0;
        margin: 0;
        li {
            list-style-type: none;
            display: block;
            padding: 0;
            margin: 0;
            padding: 3px 0;
            font-size: 14px;
            span,
            strong {
                font-weight: 300;
                color: #00AFFF;
            }
        }
    }
    .frm-row {
        margin: 0 -10px;
    }
    .slider-group {
        &:before,
        &:after {
            display: table;
            content: "";
            line-height: 0;
        }
    }
    .frm-row {
        &:before,
        &:after {
            display: table;
            content: "";
            line-height: 0;
        }
    }
    .slider-group:after {
        clear: both;
    }
    .frm-row {
        &:after {
            clear: both;
        }
        .colm {
            min-height: 1px;
            padding-left: 10px;
            padding-right: 10px;
            position: relative;
            float: left;
        }
        .colm1 {
            width: 8.33%;
        }
        .colm2 {
            width: 16.66%;
        }
        .colm3 {
            width: 25%;
        }
        .colm4 {
            width: 33.33%;
        }
        .colm5 {
            width: 41.66%;
        }
        .colm6 {
            width: 50%;
        }
        .colm7 {
            width: 58.33%;
        }
        .colm8 {
            width: 66.66%;
        }
        .colm9 {
            width: 75%;
        }
        .colm10 {
            width: 83.33%;
        }
        .colm11 {
            width: 91.66%;
        }
        .colm12 {
            width: 100%;
        }
        .colm1-5 {
            width: 20%;
        }
        .colm1-8 {
            width: 12.5%;
        }
    }
    .spacer {
        border-top: 1px solid #CFCFCF;
        display: block;
        height: 0;
    }
    .spacer-t10 {
        margin-top: 10px;
    }
    .spacer-b10 {
        margin-bottom: 10px;
    }
    .spacer-t15 {
        margin-top: 15p;
    }
    .spacer-b15 {
        margin-bottom: 15px;
    }
    .spacer-t20 {
        margin-top: 20px;
    }
    .spacer-b20 {
        margin-bottom: 20px;
    }
    .spacer-t25 {
        margin-top: 25px;
    }
    .spacer-b25 {
        margin-bottom: 25px;
    }
    .spacer-t30 {
        margin-top: 30px;
    }
    .spacer-b30 {
        margin-bottom: 30px;
    }
    .spacer-t40 {
        margin-top: 40px;
    }
    .spacer-b40 {
        margin-bottom: 40px;
    }
    .frm-row {
        .pad-l10 {
            padding-left: 10px;
        }
        .pad-r10 {
            padding-right: 10px;
        }
        .pad-l20 {
            padding-left: 20px;
        }
        .pad-r20 {
            padding-right: 20px;
        }
        .pad-l30 {
            padding-left: 30px;
        }
        .pad-r30 {
            padding-right: 30px;
        }
        .pad-l40 {
            padding-left: 40px;
        }
        .pad-r40 {
            padding-right: 40px;
        }
    }
    .bdl {
        border-left: 1px solid #CFCFCF;
    }
    .bdr {
        border-right: 1px solid #CFCFCF;
    }
    .fine-grey {
        color: #999;
    }
    .small-text {
        font-size: 11px;
        font-style: normal;
    }
    .text-align {
        height: 42px;
        line-height: 42px;
    }
    .align-right {
        text-align: right;
    }
    .align-center {
        text-align: center;
    }
    .price-box {
        padding: 30px;
        text-align: center;
        position: relative;
        border: 1px solid #CFCFCF;
        -webkit-box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.05);
        -moz-box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.05);
        -o-box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.05);
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.05);
        p {
            line-height: 1.5em;
            font-weight: 300;
            font-size: 16px;
            color: #526066;
            margin-bottom: 0;
        }
        h5 {
            text-transform: uppercase;
            font-weight: 300;
            margin: 0;
            font-size: 15px;
            color: #B0B2B9;
            letter-spacing: 2px;
        }
        h4 {
            font-size: 60px;
            font-weight: 400;
            margin: 0;
            color: #626262;
        }
    }
    .selected-box h4 {
        color: #4ebd4a;
    }
    .price-box h4 {
        sup {
            position: relative;
            font-size: 30px;
            vertical-align: top;
            top: 15px;
        }
        .per-month {
            font-size: 14px;
        }
    }
    .expand {
        height: 50px;
        line-height: 50px !important;
        border-radius: 3px;
    }
    .ribbon,
    .ribbon-large {
        width: 75px;
        height: 78px;
        overflow: hidden;
        position: absolute;
        right: -2px;
        top: -2px;
        z-index: 1;
    }
    .ribbon-inner {
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        -webkit-box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.15);
        -o-box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.15);
        -webkit-transform: translate3d(0, 0, 0);
        -webkit-backface-visibility: hidden;
        -webkit-perspective: 1000;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        background: #4ebd4a;
        letter-spacing: 4px;
        text-align: center;
        position: relative;
        font-weight: 700;
        font-size: 14px;
        padding: 7px 0;
        width: 100px;
        color: #fff;
        z-index: 1;
        left: 3px;
        top: 6px;
        &:before,
        &:after {
            content: "";
            border-top: 3px solid #3c9b39;
            border-left: 3px solid transparent;
            border-right: 3px solid transparent;
            position: absolute;
            bottom: -3px;
        }
        &:before {
            left: 0px;
        }
        &:after {
            right: 0px;
        }
    }
    .ribbon-large {
        width: 115px;
        height: 118px;
        .ribbon-inner {
            width: 160px;
            left: -8px;
            top: 28px;
        }
    }
    .sfcode {
        padding-left: 24px;
    }
    .captcode {
        padding: 0;
        position: relative;
        img {
            position: relative;
            top: 1px;
        }
    }
    .refresh-captcha {
        position: absolute;
        background: #4EBD4A;
        border: 3px solid #3C9B39;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        -o-border-radius: 30px;
        border-radius: 30px;
        right: -15px;
        height: 32px;
        width: 32px;
        top: 4px;
        i {
            position: absolute;
            text-align: center;
            line-height: 26px;
            font-size: 17px;
            color: #fff;
            left: 24%;
        }
    }
    .smart-hidden-elem {
        position: absolute;
        visibility: hidden;
        margin: 0;
        padding: 0;
    }
    .refresh-black {
        background: #505558;
        border-color: #333333;
    }
    .refresh-blue {
        background: #00acee;
        border-color: #0087bb;
    }
    .refresh-green {
        background: #0E993C;
        border-color: #0B792F;
    }
    .refresh-purple {
        background: #9464e2;
        border-color: #7639da;
    }
    .refresh-red {
        background: #ee4f3d;
        border-color: #e42914;
    }
    .refresh-yellow {
        background: #faa226;
        border-color: #e88a05;
    }
}


/* @form grid
----------------------------------- */


/* @form rows 
--------------------------------- */


/* @form columns 
----------------------------------- */


/* @spacers 
--------------------------------------- */


/* @margin spacers :: modify accordingly 
-------------------------------------------- */


/* @padding spacers :: modify accordingly 
-------------------------------------------------- */


/* @border spacers + text adjust
-------------------------------------------------- */


/* @element alignment
-------------------------------------------------- */


/* @simple price boxes :: depend on grid
-------------------------------------------------- */


/* @simple price boxes ribbon IE8+
----------------------------------------- */


/* @captcha refresh button + icon 
-------------------------------------------------- */


/* @captcha refresh button themes
-------------------------------------------------- */


/* IE10+ Fixes  
------------------------------------------------------------------------ */

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
    .smart-forms {
        .select .arrow {
            &:after,
            &:before {
                display: block;
            }
        }
        .checkbox:before {
            top: 4px;
            left: 3px;
        }
    }
}


/* @Fix old Android + IOS
------------------------------------------------------------- */

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .smart-forms {
        .option,
        .rating,
        .switch,
        .captcode {
            -webkit-animation: bugfix infinite 1s;
        }
        .switch {
            margin-right: 10px;
            margin-bottom: 5px;
        }
        .option {
            margin-right: 15px;
        }
        .switch-round>.switch-label:after {
            font: 30px/20px Times, Serif;
        }
    }
    @-webkit-keyframes bugfix {
        from {
            padding: 0;
        }
        to {
            padding: 0;
        }
    }
}


/* @responsiveness for tablets + smart mobile 
-------------------------------------------------- */

@media (max-width: 800px) {
    .smart-forms {
        .frm-row {
            margin: 0;
            .colm {
                width: 100%;
                float: none;
                padding: 0;
            }
        }
        .bdl {
            border-left: 0;
        }
        .bdr {
            border-right: 0;
        }
        .align-right {
            text-align: left;
        }
    }
}